/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../../../utils'
import { BackendError } from '../../../RequestError'
import { fetchApi } from '../../../fetchApi'
import {
  Block,
  BlockType,
  RemoteBlock,
  RepeatedMeasuresType,
  parseRemoteBlock,
  prepareBlockForSave,
  prepareQuestionBlockForSave,
  prepareStaticContentBlockForSave,
} from '../blocks'

export interface RemoteRepeatedMeasures {
  id: string
  title?: string
  type: RepeatedMeasuresType
  description?: string
  max_measures?: number
  blocks?: RemoteBlock[]
  subsection: string
}

export interface RepeatedMeasures {
  id?: string
  tempId?: string
  title?: string
  maxMeasures?: number
  description?: string
  blocks?: (Block & { order?: number })[]
  subsectionId?: string
}

export const parseRemoteRepeatedMeasures = (remoteRepeatedMeasures: RemoteRepeatedMeasures) => {
  return {
    id: String(remoteRepeatedMeasures.id),
    title: remoteRepeatedMeasures.title,
    description: remoteRepeatedMeasures.description,
    maxMeasures: remoteRepeatedMeasures.max_measures,
    blocks: remoteRepeatedMeasures.blocks?.map(parseRemoteBlock) || [],
    subsectionId: String(remoteRepeatedMeasures.subsection) || null,
  }
}

export const prepareRepeatedMeasuresForSave = (
  repeatedMeasures: RepeatedMeasures,
  order: number,
  subsectionId: string,
) => {
  return {
    title: repeatedMeasures.title,
    description: repeatedMeasures.description,
    subsection: subsectionId,
    max_measures: repeatedMeasures.maxMeasures,
    order,
    blocks:
      repeatedMeasures.blocks?.map(block => {
        if (block.blockType === BlockType.Question) {
          return prepareQuestionBlockForSave(block, block.order, subsectionId)
        }

        if (block.blockType === BlockType.StaticContent) {
          return prepareStaticContentBlockForSave(block, block.order, subsectionId)
        }

        return null
      }) || [],
  }
}

interface SaveNestedRepeatedMeasuresBlockOptions {
  sectionId: string
  subsectionId: string
  studyId: string
  order: number
  block: Block
  repeatedMeasuresId: string
}

export interface SaveNestedRepeatedMeasuresBlockResponseHandlers {
  onSuccess?: (block: Block) => void
  onRequestError?: (code: number) => void
  onDuplicateVariable?: () => void
  onMaxBlocksCountReached?: () => void
}

const updateNestedRepeatedMeasuresBlock = ({
  sectionId,
  subsectionId,
  studyId,
  order,
  block,
  repeatedMeasuresId,
}: SaveNestedRepeatedMeasuresBlockOptions) => {
  const path = `ecrf/${sectionId}/${subsectionId}/block/repeated_measure/${repeatedMeasuresId}/block/${block.id}`
  const query = prepareBlockForSave(block, order, subsectionId)
  const { req, cancel } = fetchApi.patch<RemoteBlock>(path, query, { studyId })

  return { req, cancel }
}

const createNestedRepeatedMeasuresBlock = ({
  sectionId,
  subsectionId,
  studyId,
  order,
  block,
  repeatedMeasuresId,
}: SaveNestedRepeatedMeasuresBlockOptions) => {
  const path = `ecrf/${sectionId}/${subsectionId}/block/repeated_measure/${repeatedMeasuresId}/block`
  const { req, cancel } = fetchApi.post<RemoteBlock>(path, prepareBlockForSave(block, order, subsectionId), { studyId })

  return { req, cancel }
}

export const saveNestedRepeatedMeasuresBlock = (
  options: SaveNestedRepeatedMeasuresBlockOptions,
  responseHandlers?: SaveNestedRepeatedMeasuresBlockResponseHandlers,
) => {
  const { req, cancel } = options.block.id
    ? updateNestedRepeatedMeasuresBlock(options)
    : createNestedRepeatedMeasuresBlock(options)

  req.then(({ body: block, error, status }) => {
    if (error) {
      createErrorsHandlers<SaveNestedRepeatedMeasuresBlockResponseHandlers>(
        {
          [BackendError.ECRF_BLOCKS_IN_REPEATED_MEASURE_LIMIT_REACHED]: 'onMaxBlocksCountReached',
          [BackendError.ECRF_QUESTION_VARIABLE_NOT_UNIQUE]: 'onDuplicateVariable',
        },
        error,
        responseHandlers,
        status,
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteBlock(block))
    }
  })

  return cancel
}

interface DeleteNestedRepeatedMeasuresBlockOptions {
  sectionId: string
  subsectionId: string
  studyId: string
  repeatedMeasuresId: string
  blockId: string
}

export interface DeleteNestedRepeatedMeasuresBlockResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const deleteNestedRepeatedMeasuresBlock = (
  { studyId, sectionId, subsectionId, repeatedMeasuresId, blockId }: DeleteNestedRepeatedMeasuresBlockOptions,
  responseHandlers?: DeleteNestedRepeatedMeasuresBlockResponseHandlers,
) => {
  const { req, cancel } = fetchApi.delete(
    `ecrf/${sectionId}/${subsectionId}/block/repeated_measure/${repeatedMeasuresId}/block/${blockId}`,
    {},
    { studyId },
  )

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteNestedRepeatedMeasuresBlockResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
