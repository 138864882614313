export enum Feature {
  Ecrf = 'ECRF',
  Epro = 'EPRO',
  Econsent = 'ECONSENT',
  Econsult = 'ECONSULT',
  SubjectRepository = 'SUBJECT_REPOSITORY',
  Recruitment = 'RECRUITMENT',
  Automation = 'AUTOMATION',
  Payments = 'PAYMENTS',
  SideBySide = 'SIDE_BY_SIDE',
  Bookings = 'BOOKINGS',
}
