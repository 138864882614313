import { TemplateApiPaths, createFromTemplates, insertTemplates } from '../forms'

interface InsertEconsentBlockTemplatesOptions {
  studyId: string
  subsectionId: string
  templateIds: string[]
  formId?: string
}

const econsentBlockTemplateMapping = {
  subsectionId: 'subsection_id',
  templateIds: 'template_ids',
  formId: 'econsent_id',
}

export const insertEconsentBlockTemplates = insertTemplates<InsertEconsentBlockTemplatesOptions>(
  TemplateApiPaths.EconsentBlocks,
  econsentBlockTemplateMapping,
)

interface InsertEconsentSubsectionTemplatesOptions {
  studyId: string
  sectionId: string
  templateIds: string[]
}

const econsentSubsectionTemplateMapping = {
  sectionId: 'section_id',
  templateIds: 'template_ids',
}

export const insertEconsentSubsectionTemplates = insertTemplates<InsertEconsentSubsectionTemplatesOptions>(
  TemplateApiPaths.EconsentSubsections,
  econsentSubsectionTemplateMapping,
)

interface InsertEconsentSectionTemplatesOptions {
  studyId: string
  templateIds: string[]
  formId?: string
}

const econsentSectionTemplateMapping = {
  templateIds: 'template_ids',
  formId: 'econsent_id',
}

export const insertEconsentSectionTemplates = insertTemplates<InsertEconsentSectionTemplatesOptions>(
  TemplateApiPaths.EconsentSections,
  econsentSectionTemplateMapping,
)

interface InsertEconsentFullTemplatesOptions {
  studyId: string
  templateId: string
  formId: string
}

const econsentFullTemplateMapping = {
  templateId: 'template_id',
  formId: 'econsent_id',
}

export const insertEconsentFullTemplates = insertTemplates<InsertEconsentFullTemplatesOptions>(
  TemplateApiPaths.EconsentFull,
  econsentFullTemplateMapping,
)

interface CreateEconsentFullTemplatesOptions {
  studyId: string
  templateId?: string
  formId?: string
}

const createEconsentFullFromTemplateMapping = {
  formId: 'econsent_id',
  templateId: 'template_id',
}

export const createEconsentFromFullTemplates = createFromTemplates<CreateEconsentFullTemplatesOptions>(
  TemplateApiPaths.EconsentFull,
  createEconsentFullFromTemplateMapping,
)

interface CreateEconsentTemplatesOptions {
  studyId: string
  templateIds?: string[]
  formId?: string
}

const createEconsentFromTemplateMapping = {
  formId: 'econsent_id',
  templateIds: 'template_ids',
}

export const createEconsentFromSectionTemplates = createFromTemplates<CreateEconsentTemplatesOptions>(
  TemplateApiPaths.EconsentSections,
  createEconsentFromTemplateMapping,
)

export const createEconsentFromSubsectionTemplates = createFromTemplates<CreateEconsentTemplatesOptions>(
  TemplateApiPaths.EconsentSubsections,
  createEconsentFromTemplateMapping,
)
