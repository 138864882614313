import './DatacAdvancedFilters.less'

import React, { useEffect, useState } from 'react'
import { Scrollbar } from 'react-scrollbars-custom'

import { DatacIconName, DatacOption } from '..'
import { useScopedIntl } from '../../hooks'
import {
  BlockType,
  Condition,
  ConditionalLogicOperator,
  DateTimeQuestion,
  DateTimeSubtype,
  FetchQuestionsDependenciesResponseHandlers,
  QuestionType,
  RadioQuestion,
  Structure,
  TableVariable,
  selectQuestionsTypes,
} from '../../requests'
import { FilterWizard } from './FilterWizard/FilterWizard'

export const noInputOperators = [ConditionalLogicOperator.Empty, ConditionalLogicOperator.NotEmpty]

export interface AdvancedFilter {
  type: QuestionType
  name: string
  label: string
  sublabel?: string
  icon?: DatacIconName
  options?: DatacOption[]
  getOptions?: (search: string, handlers: { onSuccess: (options: DatacOption[]) => void }, limit?: number) => void
  main?: boolean
  selection?: {
    operator: ConditionalLogicOperator
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  }
  showCheckbox?: boolean
}

export interface InitialFilterData {
  name: string
  operator: ConditionalLogicOperator
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}

// If you want to add different filters types, union the types here:
export type AdvancedMainFilterValue<T> = {
  operator: ConditionalLogicOperator
  value: T
}

export interface AdvancedMainFilter<T> extends AdvancedFilter {
  setValue: (value: AdvancedMainFilterValue<T>) => void
}

type VerifyMainFilter<T> = unknown extends (
  T extends AdvancedFilter
    ? T extends { setValue: (value: AdvancedMainFilterValue<T>) => void }
      ? never
      : unknown
    : unknown
)
  ? never
  : unknown

const maxFilters = 99

export const isFilterEmpty = (filter: AdvancedFilter) => {
  if (noInputOperators.includes(filter?.selection?.operator)) {
    return false
  }
  if (!filter?.selection) return true
  if (selectQuestionsTypes.includes(filter.type) && !filter.selection.value?.length) return true
  return (
    typeof filter.selection.value === 'undefined' ||
    filter.selection.value === null ||
    (filter.type === QuestionType.DateTime &&
      filter.selection?.operator === ConditionalLogicOperator.Between &&
      (!Array.isArray(filter.selection?.value) || (filter.selection?.value as any[]).includes(null)))
  )
}

export const fixFilterValue = (filter: AdvancedFilter) => {
  // for between operator, we need to have values in incremental order
  if (
    filter.selection &&
    filter.selection.operator === ConditionalLogicOperator.Between &&
    filter.selection.value?.length > 1 &&
    filter.selection.value[0] > filter.selection.value[1]
  ) {
    return {
      ...filter,
      selection: {
        ...filter.selection,
        value: [filter.selection.value[1], filter.selection.value[0]],
      },
    }
  }
  return filter
}

const getFiltersFromStructure = (structure: Structure): AdvancedFilter[] => {
  return structure?.sections.reduce(
    (acc, section) => [
      ...acc,
      ...section.subsections.reduce(
        (sectionAcc, subsection) => [
          ...sectionAcc,
          ...subsection.blocks.reduce((blockAcc, block) => {
            const blocks = blockAcc
            if (block.blockType === BlockType.Question)
              blocks.push({
                name: block.id,
                label: block.title,
                sublabel: block.variableName,
                type: block.type,
                options: selectQuestionsTypes.includes(block.type)
                  ? (block as RadioQuestion)?.config?.options
                  : undefined,
              } as AdvancedFilter)
            return blocks
          }, []),
        ],
        [],
      ),
    ],
    [],
  )
}

const getFiltersFromTableVariables = (tableVariables: TableVariable[], extraOperators: ConditionalLogicOperator[]) => {
  return tableVariables
    .filter(variable => {
      if (
        variable.type === QuestionType.Lsa ||
        (variable.type === QuestionType.DateTime &&
          (variable.config as DateTimeQuestion['config']).subtype !== DateTimeSubtype.Date)
      ) {
        return false
      }
      if (
        variable.type === QuestionType.File &&
        !(extraOperators || []).includes(ConditionalLogicOperator.Empty) &&
        !(extraOperators || []).includes(ConditionalLogicOperator.NotEmpty)
      ) {
        return false
      }
      return true
    })
    .map(
      variable =>
        ({
          name: `${variable.id}`,
          label: variable.title,
          sublabel: variable.variable,
          type: variable.type,
          options: selectQuestionsTypes.includes(variable.type)
            ? (variable?.config as RadioQuestion['config'])?.options
            : undefined,
        }) as AdvancedFilter,
    )
}

export const AdvancedFiltersToConditions = (filters: AdvancedFilter[]): Condition[] => {
  return (filters || [])
    .filter(filter => !filter.main && !isFilterEmpty(filter))
    .map(filter => ({
      referenceQuestionId: filter.name,
      answerValue: filter.selection?.value,
      operator: filter.selection?.operator,
    }))
}

function initialFiltersToAdvancedFilters<T extends AdvancedMainFilter<any>>(
  initialFilters: InitialFilterData[],
  variableFilters: AdvancedFilter[],
  mainFilters: Array<T> & VerifyMainFilter<T>,
): AdvancedFilter[] {
  return (initialFilters || [])
    .map(data => {
      const filter = [...(variableFilters || []), ...(mainFilters || [])].find(filter => filter.name === data.name)
      if (filter) {
        if ('setValue' in filter) {
          ;(filter as AdvancedMainFilter<T>).setValue({
            operator: data.operator,
            value: data.value,
          })
        }
        return {
          ...filter,
          selection: {
            operator: data.operator,
            value: data.value,
          },
          ...('setValue' in filter ? { main: true, setValue: (filter as AdvancedMainFilter<T>).setValue } : {}),
        }
      }
      return undefined
    })
    .filter(filter => !!filter)
}

export function mainFilterParser<T>() {
  return (filter: AdvancedMainFilterValue<T>) => {
    if (!Array.isArray(filter?.value) || !filter?.value?.length) return null
    return {
      selections: filter.value as T,
      operator: filter.operator,
    }
  }
}

interface Props {
  fetchQuestionsDependencies?: (responseHandlers?: FetchQuestionsDependenciesResponseHandlers) => void
  tableVariables?: TableVariable[]
  filters: AdvancedFilter[]
  setFilters: (filters: AdvancedFilter[]) => void
  mainFilters?: AdvancedMainFilter<any>[]
  maxCount?: number
  initialFilters?: InitialFilterData[]
  getBlockTypeSymbol: (type: QuestionType) => React.JSX.Element
  extraOperators?: ConditionalLogicOperator[]
  dropdownClassName?: string
}

export const DatacAdvancedFilters: React.FC<Props> = ({
  fetchQuestionsDependencies,
  tableVariables,
  filters,
  setFilters,
  mainFilters,
  maxCount,
  initialFilters,
  getBlockTypeSymbol,
  extraOperators,
  dropdownClassName,
}) => {
  const intlCommon = useScopedIntl('common')
  const [variableFilters, setVariableFilters] = useState<AdvancedFilter[]>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!tableVariables && !fetchQuestionsDependencies) {
      setIsLoading(false)
      setVariableFilters([])
    }
    if (tableVariables) {
      setIsLoading(false)
      const newVariableFilters = getFiltersFromTableVariables(tableVariables, extraOperators)
      setVariableFilters(newVariableFilters)
    }
    fetchQuestionsDependencies?.({
      onSuccess: ({ conditionalQuestions }) => {
        const newVariableFilters = getFiltersFromStructure(conditionalQuestions)
        setIsLoading(false)
        setVariableFilters(newVariableFilters)
      },
    })
  }, [tableVariables])

  useEffect(() => {
    if (!initialFilters?.length || (fetchQuestionsDependencies && !variableFilters)) return
    setFilters(initialFiltersToAdvancedFilters(initialFilters, variableFilters, mainFilters))
  }, [initialFilters, variableFilters])

  const getAvailableFilters = () => {
    if (isLoading || !variableFilters) return []
    const usedFilters = filters.map(f => f.name)
    return [
      ...mainFilters.map(
        mainFilter =>
          ({
            type: mainFilter.type,
            name: mainFilter.name,
            label: mainFilter.label,
            icon: mainFilter.icon,
            options: mainFilter.options,
            getOptions: mainFilter.getOptions,
            setValue: mainFilter.setValue,
            showCheckbox: mainFilter.showCheckbox,
            main: true,
            available: !mainFilter.selection?.value?.length,
          }) as AdvancedFilter & {
            available: boolean
          },
      ),
      ...variableFilters.map(filter => ({
        ...filter,
        available: !usedFilters.includes(filter.name),
      })),
    ]
  }

  const onClear = () => {
    setFilters([])
    mainFilters.forEach(mainFilter => {
      mainFilter.setValue(null)
    })
  }

  const addFilter = (newFilter: AdvancedFilter) => {
    setFilters([...filters, fixFilterValue(newFilter)])
  }

  const removeFilter = (index: number) => {
    const removedFilter = filters[index]
    if ('setValue' in removedFilter) {
      ;(removedFilter as AdvancedMainFilter<unknown>).setValue(null)
    }
    setFilters([...filters.slice(0, index), ...filters.slice(index + 1)])
  }

  return (
    <div className="advanced-filters">
      {filters?.length ? (
        <Scrollbar
          className="advanced-filters__active-wrapper"
          noScrollY
          style={{ width: 'fit-content', height: '3.9rem' }}
          translateContentSizeXToHolder
        >
          <div className="advanced-filters__active-content">
            {(filters || []).map((filter, index) => {
              const onChangeFilter = (newFilter: AdvancedFilter) => {
                setFilters(
                  filters?.map((filter: AdvancedFilter, key: number) => ({
                    ...(key === index ? fixFilterValue(newFilter) : filter),
                  })),
                )
              }
              return (
                <FilterWizard
                  key={filter.name}
                  filter={filter}
                  isFirst={!filters?.length}
                  isLoading={isLoading}
                  availableFilters={getAvailableFilters()}
                  onChange={onChangeFilter}
                  onRemove={() => removeFilter(index)}
                  getBlockTypeSymbol={getBlockTypeSymbol}
                  extraOperators={extraOperators}
                  dropdownClassName={dropdownClassName}
                />
              )
            })}
          </div>
        </Scrollbar>
      ) : null}
      {filters?.length < (maxCount || maxFilters) && (
        <FilterWizard
          filter={null}
          isFirst={!filters?.length}
          isLoading={isLoading}
          availableFilters={getAvailableFilters()}
          onChange={addFilter}
          canAddAnother={filters?.length + 1 < maxFilters}
          getBlockTypeSymbol={getBlockTypeSymbol}
          extraOperators={extraOperators}
          dropdownClassName={dropdownClassName}
        />
      )}
      {(mainFilters.some(f => f.selection?.value?.length) || !!filters.length) && (
        <div className="advanced-filters__clear-wrapper">
          <button type="button" className="raw advanced-filters__clear" onClick={onClear}>
            {intlCommon('clear')}
          </button>
        </div>
      )}
    </div>
  )
}
