import { QuestionBase, QuestionType, RemoteQuestionBase } from './base'

export interface RemoteLsaQuestion extends RemoteQuestionBase {
  type: QuestionType.Lsa
  config: {
    url: string
  }
}

export interface LsaQuestion extends QuestionBase {
  type: QuestionType.Lsa
  config: {
    url: string
  }
}

export const prepareLsaQuestionConfigForSave = (config: LsaQuestion['config']) => ({
  url: config.url.startsWith('http') ? config.url : `http://${config.url}`,
})

export const parseLsaTypeRemoteQuestionConfig = (config: RemoteLsaQuestion['config']) => ({
  url: config.url,
})
