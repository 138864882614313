import { useLayoutEffect, useState } from 'react'

export enum UseCheckWidthDirection {
  Up,
  Down,
}

export const useCheckWidth = (
  breakpoint = 992, // sm-breakpoint from css as default
  direction: UseCheckWidthDirection = UseCheckWidthDirection.Up,
) => {
  const [result, setResult] = useState(false)

  useLayoutEffect(() => {
    const updateWidth = () => {
      setResult(
        direction === UseCheckWidthDirection.Up ? window.innerWidth > breakpoint : window.innerWidth <= breakpoint,
      )
    }
    window.addEventListener('resize', updateWidth)
    updateWidth()
    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  return result
}
