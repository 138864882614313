/* eslint-disable camelcase */
import { QuestionBase, QuestionType, RemoteQuestionBase } from './base'

export interface RemoteRatingQuestion extends RemoteQuestionBase {
  type: QuestionType.Rating
  config: {
    min_value: number
    max_value: number
    min_label?: string
    max_label?: string
  }
}

export interface RatingQuestion extends QuestionBase {
  type: QuestionType.Rating
  config: {
    minValue: number
    maxValue: number
    minLabel?: string
    maxLabel?: string
  }
}

export const prepareRatingQuestionConfigForSave = (config: RatingQuestion['config']) => {
  return {
    min_value: config.minValue,
    max_value: config.maxValue,
    min_label: config.minLabel,
    max_label: config.maxLabel,
  }
}

export const parseRatingTypeRemoteQuestionConfig = (config: RemoteRatingQuestion['config']) => ({
  minValue: config.min_value,
  maxValue: config.max_value,
  minLabel: config.min_label,
  maxLabel: config.max_label,
})
