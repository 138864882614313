/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'

import { createErrorsHandlers, prepareSorter } from '../../utils'
import { BackendError } from '../RequestError'
import { SorterOrder } from '../SorterOrder'
import { fetchApi } from '../fetchApi'
import { CenterData, RemoteCenterData, parseRemoteCenterData } from '../generalSettings'
import { parseRemoteProgress } from '../sectionAdvancement'
import { RecordVariable, RecordVariableRemote, parseRecordVariable } from '../variables'
import { DateFormat, defaultStudyColor } from './studyCustomisation'

export enum OptionsRenderTypes {
  LABEL = 'LABEL',
  NUMBER = 'NUMBER',
}

export enum SubjectIdType {
  Center = 'CENTER',
  CenterCode = 'CENTER_CODE',
  Incremental = 'INCREMENTAL',
  FreeText = 'FREE_TEXT',
  Custom = 'CUSTOM',
}

interface RemoteStudy {
  centers_data: RemoteCenterData[]
  date_added: string
  inclusions: number
  name: string
  project_manager: string
  reference_number: string
  uuid: string
  created_inclusions: number
  status: string
  progress: number
  inclusion_variable: RecordVariableRemote
  gcp: boolean
  ecrf_needs_signature: boolean
  epro_needs_signature: boolean
  subject_name_info_enabled: boolean
  subject_contact_info_enabled: boolean
  randomisation_active: boolean
  randomisation_method: RandomisationMethod
  stratify_by_center: boolean
  start_date: string
  logo: string
  main_colour: string
  button_colour: string
  epro_enabled: boolean
  econsent_enabled: boolean
  econsult_enabled: boolean
  data_analysis_enabled: boolean
  ecrf_file_size_limit: number
  role: number
  subject_id_type: SubjectIdType
  subject_id_pattern: string
  subject_sharing_enabled: boolean
  reviews_enabled: boolean
  date_format: DateFormat
  esignature_enabled: boolean
  lsa_enabled: boolean
}

export enum StudyStatus {
  Draft = 'DRAFT',
  Live = 'LIVE',
  Suspended = 'SUSPENDED',
  Ended = 'ENDED',
  Archived = 'ARCHIVED',
}

export interface Study {
  id: string
  name: string
  reference: string
  centers: CenterData[]
  projectManager: string
  requiredInclusionsCount: number
  createdInclusionsCount: number
  dateAdded: string
  status: StudyStatus
  progress: number
  inclusionVariable: RecordVariable
  gcp: boolean
  ecrfNeedsSignature: boolean
  eproNeedsSignature: boolean
  subjectNameEnabled: boolean
  subjectContactEnabled: boolean
  randomisation: boolean
  randomisationMethod?: RandomisationMethod
  stratifyByCenter: boolean
  startDate: string
  logoId?: string
  mainColor?: string
  buttonColor?: string
  eproEnabled: boolean
  econsentEnabled: boolean
  econsultEnabled: boolean
  dataAnalysisEnabled: boolean
  ecrfFileSizeLimit: number
  roleId: string
  subjectIdType: SubjectIdType
  subjectIdPattern: string
  subjectSharingEnabled: boolean
  reviewsEnabled: boolean
  dateFormat: DateFormat
  esignatureEnabled: boolean
  lsaEnabled: boolean
}

const parseRemoteStudy = (study: RemoteStudy): Study => {
  return {
    id: study.uuid,
    name: study.name,
    requiredInclusionsCount: study.inclusions,
    createdInclusionsCount: study.created_inclusions,
    projectManager: study.project_manager,
    reference: study.reference_number,
    centers: study.centers_data.map(parseRemoteCenterData),
    dateAdded: study.date_added,
    status: parseStudyStatus(study.status),
    progress: parseRemoteProgress(study.progress),
    inclusionVariable: study.inclusion_variable.config ? parseRecordVariable(study.inclusion_variable) : null,
    gcp: !!study.gcp,
    ecrfNeedsSignature: !!study.ecrf_needs_signature,
    eproNeedsSignature: !!study.epro_needs_signature,
    subjectNameEnabled: study.subject_name_info_enabled,
    subjectContactEnabled: study.subject_contact_info_enabled,
    randomisation: !!study.randomisation_active,
    randomisationMethod: study.randomisation_method,
    stratifyByCenter: !!study.stratify_by_center,
    startDate: study.start_date,
    logoId: study.logo,
    mainColor: study.main_colour || defaultStudyColor,
    buttonColor: study.button_colour || defaultStudyColor,
    eproEnabled: !!study.epro_enabled,
    econsentEnabled: !!study.econsent_enabled,
    econsultEnabled: !!study.econsult_enabled,
    dataAnalysisEnabled: !!study.data_analysis_enabled,
    ecrfFileSizeLimit: study.ecrf_file_size_limit,
    roleId: study.role ? String(study.role) : null,
    subjectIdType: study.subject_id_type,
    subjectIdPattern: study.subject_id_pattern,
    subjectSharingEnabled: study.subject_sharing_enabled,
    reviewsEnabled: study.reviews_enabled,
    dateFormat: study.date_format,
    esignatureEnabled: !!study.esignature_enabled,
    lsaEnabled: !!study.lsa_enabled,
  }
}

const parseStudyStatus = (status: string) => {
  switch (status) {
    case 'DRAFT':
      return StudyStatus.Draft
    case 'LIVE':
      return StudyStatus.Live
    case 'SUSPENDED':
      return StudyStatus.Suspended
    case 'ENDED':
      return StudyStatus.Ended
    case 'ARCHIVED':
      return StudyStatus.Archived
    default:
      return null
  }
}

export interface StudiesSorter {
  field: keyof Study
  order: SorterOrder
}

const sorterFields = {
  dateAdded: ['date_added'],
  startDate: ['start_date'],
}

export interface StudiesFilters {
  status?: StudyStatus[]
}

const prepareStudiesFilters = (filters?: StudiesFilters) => {
  return filters?.status ? filters.status.join(',') : null
}

interface FetchStudiesOptions {
  options?: {
    sorter?: StudiesSorter
    filters?: StudiesFilters
    search?: string
    limit?: number
    offset?: number
    subjectId?: string
  }
}

interface FetchStudiesResponse {
  results: RemoteStudy[]
  all_count: number
  count: number
}

interface FetchStudiesResponseHandlers {
  onSuccess?: (studies: Study[], meta: { allStudiesCount: number; studiesCount: number }) => void
  onRequestError?: (code: number) => void
}

export const fetchStudies = ({ options }: FetchStudiesOptions, responseHandlers?: FetchStudiesResponseHandlers) => {
  const sorter = prepareSorter<typeof sorterFields, StudiesSorter>(sorterFields, options.sorter, 'dateAdded')
  const query = {
    ordering: sorter,
    status: prepareStudiesFilters(options?.filters),
    search: options?.search?.toLowerCase(),
    limit: options?.limit,
    offset: options?.offset,
    subject_id: options?.subjectId,
  }
  const { req, cancel } = fetchApi.get<FetchStudiesResponse>('studies', query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchStudiesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(
        body.results.map(study => parseRemoteStudy(study)),
        {
          allStudiesCount: body.all_count || 0,
          studiesCount: body.count || 0,
        },
      )
    }
  })

  return cancel
}

interface FetchStudyOptions {
  studyId: string
}

interface FetchStudyResponseHandlers {
  onSuccess?: (study: Study) => void
  onNoStudyFound?: () => void
  onRequestError?: (code: number) => void
}

export const fetchStudy = ({ studyId }: FetchStudyOptions, responseHandlers?: FetchStudyResponseHandlers) => {
  const { req, cancel } = fetchApi.get<RemoteStudy>(`studies/${studyId}`, {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchStudyResponseHandlers>(
        {
          403: 'onNoStudyFound',
        },
        error,
        responseHandlers,
        status,
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteStudy(body))
    }
  })

  return cancel
}

interface CreateStudyResponseHandlers {
  onSuccess?: ({ uuid }: CreateStudyResponse) => void
  onRequestError?: (code: number) => void
  onReferenceTaken?: () => void
  onTooManyCenters?: () => void
}

interface CreateStudyOptions {
  data: {
    name: string
    reference: string
    centerIds: string
    projectManager: string
    numberOfInclusions: number
  }
}

interface CreateStudyResponse {
  uuid: string
}

export const createStudy = ({ data }: CreateStudyOptions, responseHandlers?: CreateStudyResponseHandlers) => {
  const query = {
    name: data.name,
    reference_number: data.reference,
    project_manager: data.projectManager,
    center_ids: data.centerIds,
    inclusions: data.numberOfInclusions,
  }
  const { req, cancel } = fetchApi.post<CreateStudyResponse>('studies', query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<CreateStudyResponseHandlers>(
        {
          [BackendError.STUDY_REFERENCE_ALREADY_EXISTS]: 'onReferenceTaken',
          [BackendError.STUDY_CENTERS_LIMIT_REACHED]: 'onTooManyCenters',
        },
        error,
        responseHandlers,
        status,
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}

interface FetchEcrfStatusOptions {
  studyId: string
}

interface FetchEcrfStatusResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const fetchEcrfStatus = (
  { studyId }: FetchEcrfStatusOptions,
  responseHandlers?: FetchEcrfStatusResponseHandlers,
) => {
  const { req, cancel } = fetchApi.get('studies/ecrfstatus', {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<FetchEcrfStatusResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface UpdateStudyOptions {
  study: {
    id: string
    name: string
    reference: string
    projectManager: string
    centerIds: string[]
    requiredInclusionsCount: number
    status: StudyStatus
    gcp: boolean
    ecrfNeedsSignature?: boolean
    eproNeedsSignature?: boolean
    subjectNameEnabled: boolean
    subjectContactEnabled: boolean
    reviewsEnabled: boolean
    startDate: Dayjs
    logoId?: string
    mainColor?: string
    buttonColor?: string
    subjectIdType: SubjectIdType
    subjectIdPattern?: string
    dateFormat?: DateFormat
    endedToLiveReason?: string
  }
}

interface UpdateStudyResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onReferenceTaken?: () => void
  onIdPatternWrongVariable?: () => void
  onQrOn?: () => void
  onError?: () => void
}

export const updateStudy = ({ study }: UpdateStudyOptions, responseHandlers?: UpdateStudyResponseHandlers) => {
  const query = {
    name: study.name,
    reference_number: study.reference,
    project_manager: study.projectManager,
    center_ids: study.centerIds,
    inclusions: study.requiredInclusionsCount,
    status: study.status,
    gcp: study.gcp,
    ecrf_needs_signature: study.ecrfNeedsSignature,
    epro_needs_signature: study.eproNeedsSignature,
    subject_name_info_enabled: study.subjectNameEnabled,
    subject_contact_info_enabled: study.subjectContactEnabled,
    reviews_enabled: study.reviewsEnabled,
    start_date: study.startDate && dayjs(study.startDate).format('YYYY-MM-DD'),
    logo: study.logoId || null,
    main_colour: study.mainColor,
    button_colour: study.buttonColor,
    subject_id_type: study.subjectIdType,
    ...(study.subjectIdType === SubjectIdType.Custom ? { subject_id_pattern: study.subjectIdPattern } : {}),
    date_format: study.dateFormat,
    ended_to_live_reason: study.endedToLiveReason,
  }
  const { req, cancel } = fetchApi.put(`studies/${study.id}`, query, { studyId: study.id })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<UpdateStudyResponseHandlers>(
        {
          [BackendError.STUDY_REFERENCE_ALREADY_EXISTS]: 'onReferenceTaken',
          [BackendError.STUDY_WRONG_SUBJECT_ID_PATTERN]: 'onIdPatternWrongVariable',
          [BackendError.EPRO_QR_ON]: 'onQrOn',
        },
        error,
        responseHandlers,
        status,
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface FetchStudyProgressResponseHandlers {
  onSuccess?: (studyProgress: StudyProgress) => void
  onNotFound?: () => void
  onRequestError?: (code: number) => void
}

interface RemoteStudyProgress {
  study_progress: number
  sections: {
    section_id: number
    section_name: string
    section_progress: number
    completed_records: number
    inclusions_count: number
  }[]
}

export interface StudyProgress {
  progress: number
  sections: {
    id: string
    name: string
    progress: number
    completedRecordsCount: number
    inclusionsCount: number
  }[]
}

const parseFetchStudyProgressResponse = (response: RemoteStudyProgress) => {
  return {
    progress: parseRemoteProgress(response.study_progress),
    sections: response.sections.map(section => ({
      id: String(section.section_id),
      name: section.section_name,
      progress: parseRemoteProgress(section.section_progress),
      completedRecordsCount: section.completed_records,
      inclusionsCount: section.inclusions_count,
    })),
  }
}

export const fetchStudyProgress = (
  { studyId }: { studyId: string },
  responseHandlers?: FetchStudyProgressResponseHandlers,
) => {
  const { req, cancel } = fetchApi.get<RemoteStudyProgress>('studies/progress', {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchStudyProgressResponseHandlers>(
        {
          404: 'onNotFound',
        },
        error,
        responseHandlers,
        status,
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseFetchStudyProgressResponse(body))
    }
  })

  return cancel
}

interface FetchStudyDashboardResponseHandlers {
  onSuccess?: (studyDashboard: StudyDashboardCounts) => void
  onNotFound?: () => void
  onRequestError?: (code: number) => void
}

interface RemoteStudyDashboard {
  completed_count: number
  enrolled_count: number
  in_progress_count: number
  queries_count: number
  queries_open_count: number
  excluded_count: number
  screened_count: number
  screened_chart: {
    date: string
    center_abbreviation: string
    value: number
  }[]
}

interface InclusionCount {
  date: string
  center: string
  value: number
}

export interface StudyDashboardCounts {
  completedCount: number
  enrolledCount: number
  inProgressCount: number
  queriesCount: number
  openQueriesCount: number
  excludedCount: number
  screenedCount: number
  screenedChart: InclusionCount[]
}

const parseFetchStudyDashboardResponse = (response: RemoteStudyDashboard) => {
  return {
    completedCount: response.completed_count || 0,
    enrolledCount: response.enrolled_count || 0,
    inProgressCount: response.in_progress_count || 0,
    queriesCount: response.queries_count || 0,
    openQueriesCount: response.queries_open_count || 0,
    excludedCount: response.excluded_count || 0,
    screenedCount: response.screened_count || 0,
    screenedChart: response.screened_chart
      ? response.screened_chart.map(inclusion => ({
          date: dayjs(inclusion.date).format('YYYY-MM-DD'),
          center: inclusion.center_abbreviation,
          value: inclusion.value,
        }))
      : [],
  }
}

export const fetchStudyDashboard = (
  { studyId }: { studyId: string },
  responseHandlers?: FetchStudyDashboardResponseHandlers,
) => {
  const { req, cancel } = fetchApi.get<RemoteStudyDashboard>('studies/dashboard', {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchStudyDashboardResponseHandlers>(
        {
          404: 'onNotFound',
        },
        error,
        responseHandlers,
        status,
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseFetchStudyDashboardResponse({ ...body }))
    }
  })

  return cancel
}

export interface RandomisationGroup {
  name: string
  description: string
  weight: number
}

interface UpdateRandomisationSettingsOptions extends RandomisationSettings {
  studyId: string
}

interface UpdateRandomisationSettingsResponseHandlers {
  onSuccess?: (randomisationSettings: RandomisationSettings) => void
  onRequestError?: (code: number) => void
}

export const updateRandomisationSettings = (
  {
    studyId,
    groups,
    blocks,
    stratifyByCenter,
    stratifyByQuestion,
    randomisationMethod,
    randomisationDistanceMethod,
  }: UpdateRandomisationSettingsOptions,
  responseHandlers?: UpdateRandomisationSettingsResponseHandlers,
) => {
  const query = {
    randomisation_method: randomisationMethod,
    randomisation_distance_method: randomisationDistanceMethod,
    randomisation_groups: groups,
    randomisation_blocks: blocks && blocks.split(',').map(block => parseInt(block, 10)),
    stratify_by_center: stratifyByCenter,
    stratify_by_question: stratifyByQuestion || [],
  }
  const path = 'studies/randomisation'
  const { req, cancel } = fetchApi.patch<RemoteFetchRandomisationSettingsResponse>(path, query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<UpdateRandomisationSettingsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseFetchRandomisationSettingsResponse(body))
    }
  })

  return cancel
}

export enum RandomisationMethod {
  Blocks = 'BLOCKS',
  Dynamic = 'DYNAMIC',
  Import = 'IMPORT',
  DoubleBlindedImport = 'DOUBLE_BLINDED_IMPORT',
}

export enum RandomisationDistanceMethod {
  Range = 'RANGE',
  Variance = 'VARIANCE',
  Max = 'MAX',
}

interface RemoteFetchRandomisationSettingsResponse {
  randomisation_method: RandomisationMethod
  randomisation_distance_method: RandomisationDistanceMethod
  randomisation_groups: RandomisationGroup[]
  randomisation_blocks: number[]
  stratify_by_center: boolean
  stratify_by_question: number[]
}

export interface RandomisationSettings {
  randomisationMethod: RandomisationMethod
  randomisationDistanceMethod: RandomisationDistanceMethod
  groups: RandomisationGroup[]
  blocks: string
  stratifyByCenter: boolean
  stratifyByQuestion: string[]
}

const parseFetchRandomisationSettingsResponse = (response: RemoteFetchRandomisationSettingsResponse) =>
  response?.randomisation_groups
    ? {
        randomisationMethod: response.randomisation_method || RandomisationMethod.Blocks,
        randomisationDistanceMethod: response.randomisation_distance_method || RandomisationDistanceMethod.Range,
        groups: response.randomisation_groups,
        blocks: response.randomisation_blocks?.join(', '),
        stratifyByCenter: response.stratify_by_center,
        stratifyByQuestion: response.stratify_by_question
          ? response.stratify_by_question.map(question => String(question))
          : [],
      }
    : null

interface FetchRandomisationSettingsResponseHandlers {
  onSuccess?: (randomisationSettings: RandomisationSettings) => void
  onRequestError?: (code: number) => void
}

export const fetchRandomisationSettings = (
  { studyId }: { studyId: string },
  responseHandlers?: FetchRandomisationSettingsResponseHandlers,
) => {
  const path = 'studies/randomisation'
  const { req, cancel } = fetchApi.get<RemoteFetchRandomisationSettingsResponse>(path, {}, { studyId })

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<FetchRandomisationSettingsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseFetchRandomisationSettingsResponse(body))
    }
  })

  return cancel
}
