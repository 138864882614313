import { StudyAuditTrail, StudyAuditTrailsActionType, StudyAuditTrailsEventType } from './StudyAuditTrail'
import { SystemAuditTrail, SystemAuditTrailsActionType, SystemAuditTrailsEventType } from './SystemAuditTrail'

export enum AuditTrailType {
  System = 'SYSTEM',
  Study = 'STUDY',
}

export enum AuditTrailLabelType {
  Event,
  Action,
}

export type AuditTrail = SystemAuditTrail | StudyAuditTrail

export type AuditTrailsEventType = SystemAuditTrailsEventType | StudyAuditTrailsEventType

export type AuditTrailsActionType = SystemAuditTrailsActionType | StudyAuditTrailsActionType

// for those events display label "Subject" in the user field when username is null
export const defaultSubjectLabelEvents = [
  StudyAuditTrailsEventType.EproRecords,
  StudyAuditTrailsEventType.EconsentSurvey,
  StudyAuditTrailsEventType.EproFiles,
  StudyAuditTrailsEventType.EconsentFiles,
  StudyAuditTrailsEventType.VideoCall,
]
