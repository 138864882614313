import './DatacLanguageSelector.less'

import { Menu, Popover } from 'antd'
import React, { Suspense, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { DatacIcon } from '..'
import { useCheckWidth } from '../../hooks'
import { AvailableLocales } from '../../requests'
import { LocalStorageKey, changeLocale, getLanguage, setLanguage } from '../../utils'
import { DatacLoading } from '../DatacLoading'

const Flag = React.lazy(() => import('react-world-flags'))

type SingleLanguageType = {
  label: string
  icon: React.ReactElement
}
type LanguageList = { [key in AvailableLocales | string]: SingleLanguageType }

const parseLocale = <T extends string>(locale: T, flag?: React.ReactElement) =>
  ({
    [locale]: {
      label: locale.toUpperCase(),
      icon: flag,
    },
  }) as Record<T, SingleLanguageType>

export const appLanguages: LanguageList = {
  ...parseLocale(
    AvailableLocales.En,
    <Suspense fallback={<DatacLoading size="small" isLoading />}>
      <Flag code="gb" data-code="gb" />
    </Suspense>,
  ),
  ...parseLocale(
    AvailableLocales.Fr,
    <Suspense fallback={<DatacLoading size="small" isLoading />}>
      <Flag code="fr" />
    </Suspense>,
  ),
  ...parseLocale(
    AvailableLocales.It,
    <Suspense fallback={<DatacLoading size="small" isLoading />}>
      <Flag code="it" />
    </Suspense>,
  ),
  ...parseLocale(
    AvailableLocales.Pl,
    <Suspense fallback={<DatacLoading size="small" isLoading />}>
      <Flag code="pl" />
    </Suspense>,
  ),
  ...parseLocale(
    AvailableLocales.Pt,
    <Suspense fallback={<DatacLoading size="small" isLoading />}>
      <Flag code="pt" />
    </Suspense>,
  ),
  ...parseLocale(
    AvailableLocales.De,
    <Suspense fallback={<DatacLoading size="small" isLoading />}>
      <Flag code="de" />
    </Suspense>,
  ),
  ...parseLocale(
    AvailableLocales.Es,
    <Suspense fallback={<DatacLoading size="small" isLoading />}>
      <Flag code="es" />
    </Suspense>,
  ),
  ...parseLocale(
    AvailableLocales.Ja,
    <Suspense fallback={<DatacLoading size="small" isLoading />}>
      <Flag code="jp" />
    </Suspense>,
  ),
  ...parseLocale(
    AvailableLocales.Zh,
    <Suspense fallback={<DatacLoading size="small" isLoading />}>
      <Flag code="cn" />
    </Suspense>,
  ),
}

interface SingleLanguageProps {
  lang: SingleLanguageType
  onClick?: () => void
}

export const SingleLanguage: React.FC<SingleLanguageProps> = ({ lang, onClick }) => {
  const isDesktop = useCheckWidth()

  return (
    !!lang && (
      <div className="datac-language-selector__item" onClick={() => onClick && onClick()}>
        <div className="datac-language-selector__item-flag">
          {lang.icon || <Flag code={lang.label} fallback={<DatacIcon name="datac" size="small" />} />}
        </div>
        {isDesktop && <div className="datac-language-selector__item-label">{lang.label}</div>}
      </div>
    )
  )
}

interface DatacLanguageSelectorProps {
  dataToPreserveOnReload?: Record<string, string>
  languages?: string[]
  setUserLanguage: (language: string) => void
}
export const DatacLanguageSelector: React.FC<DatacLanguageSelectorProps> = ({
  dataToPreserveOnReload,
  languages,
  setUserLanguage,
}) => {
  const [isOpen, setOpen] = useState(false)
  const intl = useIntl()
  const isDesktop = useCheckWidth()
  const [currentLanguages, setCurrentLanguages] = useState<LanguageList>(null)
  const [selectedLanguage, setSelectedLanguage] = useState<SingleLanguageType>(null)

  const languageHasAppTranslation = (lang: string) => Object.values(AvailableLocales).includes(lang as AvailableLocales)

  useEffect(() => {
    if (!languages) {
      setCurrentLanguages(appLanguages)
    } else {
      setCurrentLanguages(
        languages.reduce((acc, lang) => {
          if (languageHasAppTranslation(lang)) return acc
          return { ...acc, ...parseLocale(lang) }
        }, appLanguages),
      )
    }
  }, [languages])

  useEffect(() => {
    if (currentLanguages) {
      const selectedLanguage =
        currentLanguages?.[getLanguage()] ||
        currentLanguages?.[intl.locale] ||
        currentLanguages[Object.keys(currentLanguages)[0]]

      setSelectedLanguage(selectedLanguage)

      const selectedLocale = selectedLanguage.label.toLowerCase()
      const isSupported = Object.values(appLanguages)
        .map(l => l.label.toLowerCase())
        .includes(selectedLocale)

      if (isSupported && selectedLocale !== intl.locale) {
        languageChangeHandler(selectedLocale)
      }
    }
  }, [currentLanguages])

  const languageChangeHandler = (lang: AvailableLocales | string) => {
    if (dataToPreserveOnReload) {
      localStorage.setItem(LocalStorageKey.SurveyPreservedData, JSON.stringify(dataToPreserveOnReload))
    }
    setLanguage(lang)
    setSelectedLanguage(currentLanguages[lang])
    if (!languageHasAppTranslation(lang) || intl.locale === lang) window.location.reload()
    else {
      changeLocale(lang)
      setUserLanguage(lang as AvailableLocales)
    }
  }

  const menu = (
    <Menu className="datac-language-selector__menu">
      {!!currentLanguages &&
        Object.entries(currentLanguages).map(([key, value], idx) => {
          return (
            <Menu.Item key={idx}>
              <SingleLanguage lang={value} onClick={() => languageChangeHandler(key)} />
            </Menu.Item>
          )
        })}
    </Menu>
  )

  return (
    !!currentLanguages && (
      <Popover
        content={menu}
        trigger="click"
        placement="bottomLeft"
        overlayClassName="datac-language-selector__popover"
        open={isOpen}
        onOpenChange={setOpen}
      >
        <div className="datac-language-selector__button">
          <SingleLanguage lang={selectedLanguage} />
          {isDesktop && <DatacIcon raw name={isOpen ? 'chevronUp' : 'chevronDown'} />}
        </div>
      </Popover>
    )
  )
}
