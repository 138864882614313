/* eslint-disable camelcase */
import { DateTimePeriodType, QuestionBase, QuestionType, RemoteQuestionBase } from './index'

export enum CalculError {
  EmptyFormula = 'EmptyFormula',
  IncorrectFormulaFormat = 'IncorrectFormulaFormat',
  EmptyVariablesInFormula = 'EmptyVariablesInFormula',
  VariableMismatch = 'VariableMismatch',
  VariableNoAnswer = 'VariableNoAnswer',
  IncorrectAnswerFormat = 'IncorrectAnswerFormat',
  AnswerIsNotNumberType = 'AnswerIsNotNumberType',
  IncorrectCompiledFormulaFormat = 'IncorrectCompiledFormulaFormat',
  ErrorInEvaluation = 'ErrorInEvaluation',
  ResultNotNumber = 'ResultNotNumber',
  ResultTooBigNumber = 'ResultTooBigNumber',
  ReferenceNoAnswer = 'ReferenceNoAnswer',
  InvalidReferenceQuestion = 'InvalidReferenceQuestion',
}

export enum CalculSubtype {
  Custom = 'CUSTOM',
  Age = 'AGE',
  DateTime = 'DATE_TIME',
  DynamicAge = 'DYNAMIC_AGE',
  DataDisplay = 'DATA_DISPLAY',
  SystemVariable = 'SYSTEM_VARIABLE',
}

export type CalculValue = {
  result?: string
  variableIds?: number[]
  error?: CalculError
}

export interface RemoteCalculQuestion extends RemoteQuestionBase {
  type: QuestionType.Calcul
  config: {
    subtype: string
    function?: string
    max_decimal?: number
    reference_question_id?: string
    period_amount?: number
    period_type?: string
  }
}

export interface CalculQuestion extends QuestionBase {
  type: QuestionType.Calcul
  config: {
    subtype: CalculSubtype
    function?: string
    maxDecimal?: number
    referenceQuestionId?: string
    periodAmount?: number
    periodType?: DateTimePeriodType
  }
}

export const prepareCalculQuestionConfigForSave = (config: CalculQuestion['config']) => ({
  subtype: config.subtype,
  function: config.function,
  max_decimal: config.maxDecimal,
  reference_question_id: config.referenceQuestionId,
  period_amount: config.periodAmount,
  period_type: config.periodType,
})

export const parseCalculTypeRemoteQuestionConfig = (config: RemoteCalculQuestion['config']) => ({
  subtype: config.subtype,
  function: config.function,
  maxDecimal: config.max_decimal,
  referenceQuestionId: config.reference_question_id ? String(config.reference_question_id) : null,
  periodAmount: config.period_amount,
  periodType: config.period_type,
})
