import { TemplateApiPaths, createFromTemplates, insertTemplates } from '../forms'

interface InsertEproBlockTemplatesOptions {
  studyId: string
  eproId: string
  subsectionId: string
  templateIds: string[]
}

const eproBlockTemplateMapping = {
  eproId: 'epro_id',
  subsectionId: 'subsection_id',
  templateIds: 'template_ids',
}

export const insertEproBlockTemplates = insertTemplates<InsertEproBlockTemplatesOptions>(
  TemplateApiPaths.EproBlocks,
  eproBlockTemplateMapping,
)

interface InsertEproSubsectionTemplatesOptions {
  studyId: string
  eproId: string
  sectionId: string
  templateIds: string[]
}

const eproSubsectionTemplateMapping = {
  eproId: 'epro_id',
  sectionId: 'section_id',
  templateIds: 'template_ids',
}

export const insertEproSubsectionTemplates = insertTemplates<InsertEproSubsectionTemplatesOptions>(
  TemplateApiPaths.EproSubsections,
  eproSubsectionTemplateMapping,
)

interface InsertEproSectionTemplatesOptions {
  studyId: string
  eproId: string
  templateIds: string[]
}

const eproSectionTemplateMapping = {
  eproId: 'epro_id',
  templateIds: 'template_ids',
}

export const insertEproSectionTemplates = insertTemplates<InsertEproSectionTemplatesOptions>(
  TemplateApiPaths.EproSections,
  eproSectionTemplateMapping,
)

interface InsertEproFullTemplatesOptions {
  studyId: string
  eproId: string
  templateId: string
}

const eproFullTemplateMapping = {
  eproId: 'epro_id',
  templateId: 'template_id',
}

export const insertEproFullTemplates = insertTemplates<InsertEproFullTemplatesOptions>(
  TemplateApiPaths.EproFull,
  eproFullTemplateMapping,
)

interface CreateEproFullTemplatesOptions {
  studyId: string
  eproId: string
  templateId?: string
}

const createEproFullFromTemplateMapping = {
  eproId: 'epro_id',
  templateId: 'template_id',
}

export const createEproFromFullTemplates = createFromTemplates<CreateEproFullTemplatesOptions>(
  TemplateApiPaths.EproFull,
  createEproFullFromTemplateMapping,
)

interface CreateEproTemplatesOptions {
  studyId: string
  eproId: string
  templateIds?: string[]
}

const createEproFromTemplateMapping = {
  eproId: 'epro_id',
  templateIds: 'template_ids',
}

export const createEproFromSectionTemplates = createFromTemplates<CreateEproTemplatesOptions>(
  TemplateApiPaths.EproSections,
  createEproFromTemplateMapping,
)

export const createEproFromSubsectionTemplates = createFromTemplates<CreateEproTemplatesOptions>(
  TemplateApiPaths.EproSubsections,
  createEproFromTemplateMapping,
)
