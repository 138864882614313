/* eslint-disable camelcase */
import { createErrorsHandlers, prepareSorter } from '../../utils'
import { SorterOrder } from '../SorterOrder'
import { fetchApi } from '../fetchApi'
import { ConditionalLogicOperator } from '../forms'
import { CenterData, RemoteCenterData } from '../generalSettings'
import {
  RecordStatus,
  RemoteSectionAdvancement,
  SectionAdvancement,
  parseRemoteRecordAdvancement,
} from '../sectionAdvancement'

export enum EproFormStatus {
  Published = 'PUBLISHED',
  Ended = 'ENDED',
  Draft = 'DRAFT',
  Archived = 'ARCHIVED',
}

export enum EproRecordSource {
  Automation = 'AUTOMATION',
  Api = 'API',
  Manual = 'MANUAL',
}

export interface EproForm {
  eproName: string
  surveyName: string
  id: string
  studyId: string
  questionsNum: number
  creationDate: Date
  publishDate: Date
  recordsNum: number
  completedRecordsNum: number
  status: EproFormStatus
  lastEproRecordId?: string
  hasReminders: boolean
  occuRencesPlanned: number
  qrOn: boolean
  order: number
}

export interface RemoteEproForm {
  epro_name: string
  survey_name: string
  study: string
  id: number
  date_added: string
  date_published: string
  questions_count: number
  records_count: number
  completed_records_count: number
  status: EproFormStatus
  last_epro_record_id?: string
  has_reminders_activated: boolean
  occurrences_planned: number
  qr_on: boolean
  order: number
}

export type EproRecordQuestionsCount = { done: number; total: number }

export const EproRecordStatus = {
  Created: RecordStatus.Created,
  Sent: RecordStatus.Sent,
  Scheduled: RecordStatus.Scheduled,
  Uncompleted: RecordStatus.Uncompleted,
  Completed: RecordStatus.Completed,
  Archived: RecordStatus.Archived,
}

interface RemoteEproRecord {
  epro: number
  id: number
  investigator: string
  start_date: string
  status: RecordStatus
  subject: string
  questions_count: number
  invitation_url: string
  answers_count: number
  occurrence_no: number
  sections?: RemoteSectionAdvancement[]
  randomisation_id: string
  randomisation_group_name: string
  epro_name?: string
  subject_has_phone: boolean
  subject_has_email: boolean
  completion_date: string
  center: Partial<RemoteCenterData>
  source: EproRecordSource
}

export interface EproRecord {
  id: string
  subjectId: string
  sendDate: Date
  completionDate: Date
  investigator: string
  invitationUrl: string
  questionsCount: EproRecordQuestionsCount
  occurrenceNumber: number
  status: RecordStatus
  name: string
  progress: number
  sectionsAdvancement?: { [sectionId: string]: SectionAdvancement }
  randomisationId: string
  randomisationGroup: string
  eproName?: string
  eproId?: string
  hasPhone: boolean
  hasEmail: boolean
  center: Partial<CenterData>
  source: EproRecordSource
}

const parseRemoteEproForm = (remoteEpro: Partial<RemoteEproForm>) => ({
  eproName: remoteEpro?.epro_name,
  surveyName: remoteEpro?.survey_name,
  id: String(remoteEpro?.id),
  creationDate: remoteEpro.date_added && new Date(remoteEpro.date_added),
  publishDate: remoteEpro.date_published && new Date(remoteEpro.date_published),
  studyId: remoteEpro?.study,
  questionsNum: remoteEpro?.questions_count,
  recordsNum: remoteEpro?.records_count,
  status: remoteEpro?.status,
  completedRecordsNum: remoteEpro?.completed_records_count,
  lastEproRecordId: remoteEpro?.last_epro_record_id,
  hasReminders: remoteEpro?.has_reminders_activated,
  occuRencesPlanned: remoteEpro?.occurrences_planned,
  qrOn: remoteEpro?.qr_on,
  order: remoteEpro?.order,
})

const parseRemoteEproRecord = (remoteRecord: RemoteEproRecord) => ({
  id: String(remoteRecord.id),
  subjectId: remoteRecord.subject,
  investigator: remoteRecord.investigator,
  sendDate: remoteRecord.start_date ? new Date(remoteRecord.start_date) : null,
  completionDate: remoteRecord.completion_date ? new Date(remoteRecord.completion_date) : null,
  invitationUrl: remoteRecord.invitation_url,
  questionsCount: { done: remoteRecord.answers_count, total: remoteRecord.questions_count },
  occurrenceNumber: remoteRecord.occurrence_no,
  status: remoteRecord.status,
  name: String(remoteRecord.id),
  progress: 0,
  sectionsAdvancement: parseRemoteRecordAdvancement(remoteRecord.sections),
  randomisationId: remoteRecord.randomisation_id,
  randomisationGroup: remoteRecord.randomisation_group_name,
  eproName: remoteRecord.epro_name,
  eproId: String(remoteRecord.epro),
  hasPhone: remoteRecord.subject_has_phone,
  hasEmail: remoteRecord.subject_has_email,
  center: {
    abbreviation: remoteRecord.center.abbreviation,
    code: remoteRecord.center.code,
  },
  source: remoteRecord.source,
})

interface CreateEproFormResponseHandlers {
  onSuccess?: (eproId: string) => void
  onRequestError?: (code: number) => void
}

interface CreateEproFormOptions {
  eproName: string
  surveyName: string
  studyId: string
}

interface CreateEproFormResponse {
  id: string
}

export const createEproForm = (
  { studyId, eproName, surveyName }: CreateEproFormOptions,
  responseHandlers?: CreateEproFormResponseHandlers,
) => {
  const query = {
    epro_name: eproName,
    survey_name: surveyName,
    study: studyId,
  }
  const { req, cancel } = fetchApi.post<CreateEproFormResponse>('epro', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<CreateEproFormResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.id)
    }
  })

  return cancel
}

interface ArchiveEproFormOptions {
  studyId: string
  eproFormId: string
}

interface ArchiveEproFormResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const archiveEproForm = (
  { studyId, eproFormId }: ArchiveEproFormOptions,
  responseHandlers?: ArchiveEproFormResponseHandlers,
) => {
  const { req, cancel } = fetchApi.delete<RemoteEproForm>(`epro/${eproFormId}`, {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<ArchiveEproFormResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

type FetchEproFormsResponse = {
  results: RemoteEproForm[]
  count: number
  statuses: {
    published: number
    draft: number
    ended: number
    archived: number
  }
}

interface FetchEproFormsResponseHandlers {
  onSuccess?: (eproForms: EproForm[], statusCounts: Record<EproFormStatus, number>) => void
  onRequestError?: (code: number) => void
}

interface FetchEproFormsOptions {
  studyId: string
  status?: EproFormStatus[]
  subjectId?: string
  options?: {
    offset: number
    limit: number
    search?: string
  }
}

export const fetchEproForms = (
  { studyId, status, subjectId, options }: FetchEproFormsOptions,
  responseHandlers?: FetchEproFormsResponseHandlers,
) => {
  const { req, cancel } = fetchApi.get<FetchEproFormsResponse>(
    'studies/epro',
    { subject_uuid: subjectId, status, limit: options?.limit, offset: options?.offset, search: options?.search },
    { studyId },
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchEproFormsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      const statuses = Object.keys(body.statuses).reduce(
        (acc, cur) => ({
          ...acc,
          [cur.toUpperCase() as EproFormStatus]: body.statuses[cur as keyof FetchEproFormsResponse['statuses']],
        }),
        {} as Record<EproFormStatus, number>,
      )
      responseHandlers.onSuccess(body.results.map(parseRemoteEproForm), statuses)
    }
  })

  return cancel
}

interface FetchEproFormOptions {
  studyId: string
  eproFormId: string
}

interface FetchEproFormResponseHandlers {
  onSuccess?: (eproForm: EproForm) => void
  onRequestError?: (code: number) => void
}

export const fetchEpro = (
  { studyId, eproFormId }: FetchEproFormOptions,
  responseHandlers?: FetchEproFormResponseHandlers,
) => {
  const { req, cancel } = fetchApi.get<RemoteEproForm>(`epro/${eproFormId}`, {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchEproFormResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteEproForm(body))
    }
  })

  return cancel
}

export interface EproRecordsSorter {
  field: keyof EproRecord
  order: SorterOrder
}

const sorterFields = {
  subjectId: ['subject_in_study_id'],
  eproName: ['epro_name'],
  status: ['status'],
  sendDate: ['start_date'],
  completionDate: ['completion_date'],
  occurrenceNumber: ['occurrence_no'],
  center: ['center'],
  source: ['source'],
}

interface FetchEproRecordsOptions {
  studyId: string
  filters?: {
    eproId?: {
      selections: string[]
      operator: ConditionalLogicOperator
    }
    eproStatus?: {
      selections: EproFormStatus[]
      operator: ConditionalLogicOperator
    }
    status?: {
      selections: RecordStatus[]
      operator: ConditionalLogicOperator
    }
    center?: {
      selections: string[]
      operator: ConditionalLogicOperator
    }
    sendDate?: {
      selections: string[]
      operator: ConditionalLogicOperator
    }
    completionDate?: {
      selections: string[]
      operator: ConditionalLogicOperator
    }
    occurrence?: {
      selections: number[]
      operator: ConditionalLogicOperator
    }
    subject?: {
      selections: string[]
      operator: ConditionalLogicOperator
    }
    randomisationId?: {
      selections: string[]
      operator: ConditionalLogicOperator
    }
  }
  options?: {
    limit?: number
    offset?: number
    sorter?: EproRecordsSorter
    search?: string
  }
}

interface FetchEproRecordsSuccessProps {
  eproRecords: EproRecord[]
  allEproRecordsCount: number
  canSendEmailCount?: number
  canSendSmsCount?: number
  allRecordIds?: string[]
  statuses?: EproRecordsStatusCounters
}
interface FetchEproRecordsResponseHandlers {
  onSuccess?: (props: FetchEproRecordsSuccessProps) => void
  onRequestError?: (code: number) => void
}

export interface EproRecordsStatusCounters {
  scheduled: number
  sent: number
  completed: number
  uncompleted: number
  created: number
  archived: number
}
interface FetchEproRecordsResponse {
  count: number
  can_send_email_count: number
  can_send_sms_count: number
  all_record_ids: string[]
  next: number
  previous: number
  results: RemoteEproRecord[]
  statuses: EproRecordsStatusCounters
}

export const fetchEproRecords = (
  { studyId, filters, options }: FetchEproRecordsOptions,
  responseHandlers?: FetchEproRecordsResponseHandlers,
) => {
  const sorter = prepareSorter<typeof sorterFields, EproRecordsSorter>(sorterFields, options.sorter)
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter.join(','),
    status: filters?.status || undefined,
    center: filters?.center || undefined,
    epro_id: filters?.eproId || undefined,
    epro_status: filters?.eproStatus || undefined,
    send_date: filters?.sendDate || undefined,
    completion_date: filters?.completionDate || undefined,
    occurrence_no: filters?.occurrence || undefined,
    subject_id: filters?.subject || undefined,
    randomisation_id: filters?.randomisationId || undefined,
    search: options.search || '',
    filter_lookup: options.search || '',
  }

  const { req, cancel } = fetchApi.post<FetchEproRecordsResponse>(`epro/records`, query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchEproRecordsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        eproRecords: body.results.map(parseRemoteEproRecord),
        allEproRecordsCount: body.count,
        allRecordIds: body.all_record_ids,
        canSendEmailCount: body.can_send_email_count,
        canSendSmsCount: body.can_send_sms_count,
        statuses: body.statuses,
      })
    }
  })

  return cancel
}

interface FetchSubjectEproRecordsOptions {
  subjectId: string
  studyId: string
  options?: {
    limit?: number
    offset?: number
    sorter?: EproRecordsSorter
  }
}

export const fetchSubjectEproRecords = (
  { studyId, subjectId, options }: FetchSubjectEproRecordsOptions,
  responseHandlers?: FetchEproRecordsResponseHandlers,
) => {
  const sorter = prepareSorter<typeof sorterFields, EproRecordsSorter>(sorterFields, options.sorter)
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
  }

  const { req, cancel } = fetchApi.get<FetchEproRecordsResponse>(`epro/records/${subjectId}`, query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchEproRecordsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        eproRecords: body.results.map(parseRemoteEproRecord),
        allEproRecordsCount: body.count,
      })
    }
  })

  return cancel
}

interface FetchEproRecordOptions {
  studyId: string
  eproId: string
  recordId: string
}

interface FetchEproRecordResponseHandlers {
  onSuccess?: (eproRecord: EproRecord) => void
  onNotFound?: () => void
  onRequestError?: (code: number) => void
}

export const fetchEproRecord = (
  { studyId, eproId, recordId }: FetchEproRecordOptions,
  responseHandlers?: FetchEproRecordResponseHandlers,
) => {
  const { req, cancel } = fetchApi.get<RemoteEproRecord>(`epro/${eproId}/${recordId}`, {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchEproRecordResponseHandlers>(
        {
          404: 'onNotFound',
        },
        error,
        responseHandlers,
        status,
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteEproRecord(body))
    }
  })

  return cancel
}

interface ArchiveEproRecordOptions {
  studyId: string
  eproId: string
  eproRecordId: string
}

interface ArchiveEproRecordResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const archiveEproRecord = (
  { studyId, eproId, eproRecordId }: ArchiveEproRecordOptions,
  responseHandlers?: ArchiveEproRecordResponseHandlers,
) => {
  const { req, cancel } = fetchApi.delete<RemoteEproRecord>(`epro/${eproId}/${eproRecordId}`, {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<ArchiveEproRecordResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface BulkArchiveEproRecordOptions {
  studyId: string
  eproId: string
  eproRecordIds: string[]
}

interface BulkArchiveEproRecordResponseHandlers {
  onSuccess?: (count: number) => void
  onRequestError?: (code: number) => void
}

export const bulkArchiveEproRecord = (
  { studyId, eproId, eproRecordIds }: BulkArchiveEproRecordOptions,
  responseHandlers?: BulkArchiveEproRecordResponseHandlers,
) => {
  const { req, cancel } = fetchApi.post<{ archived_count: number }>(
    `epro/${eproId}/records/archive`,
    { record_ids: eproRecordIds },
    { studyId },
  )

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<BulkArchiveEproRecordResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.archived_count)
    }
  })

  return cancel
}

interface ReorderEproFormResponseHandlers {
  onSuccess?: (eproId: string) => void
  onRequestError?: (code: number) => void
}

interface ReorderEproFormOptions {
  id: string
  order: number
  studyId: string
}

interface ReorderEproFormResponse {
  id: string
}

export const reorderEproForm = (
  { id, order, studyId }: ReorderEproFormOptions,
  responseHandlers?: ReorderEproFormResponseHandlers,
) => {
  const query = {
    order,
  }
  const { req, cancel } = fetchApi.patch<ReorderEproFormResponse>(`epro/${id}/update_order`, query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<ReorderEproFormResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.id)
    }
  })

  return cancel
}
