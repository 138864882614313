/* eslint-disable camelcase */
import { QuestionBase, QuestionType, RemoteQuestionBase } from './base'

export enum SliderTypeOptions {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export interface RemoteSliderQuestion extends RemoteQuestionBase {
  type: QuestionType.Slider
  config: {
    slider_type: SliderTypeOptions
    min_value: string
    max_value: string
    increment: string
    show_label: boolean
    show_labels: boolean
    show_ticks: boolean
    show_tick_value: boolean
    enable_colors: boolean
    min_label?: string
    max_label?: string
  }
}

export interface SliderQuestion extends QuestionBase {
  type: QuestionType.Slider
  config: {
    sliderType: SliderTypeOptions
    minValue: number
    maxValue: number
    increment: number
    showLabels: boolean
    showTicks: boolean
    showTickValue: boolean
    enableColors: boolean
    showValue: boolean
    minLabel?: string
    maxLabel?: string
  }
}

export const prepareSliderQuestionConfigForSave = (config: SliderQuestion['config']) => {
  return {
    slider_type: config.sliderType,
    min_value: config.minValue,
    max_value: config.maxValue,
    increment: config.increment,
    show_labels: !!config.showLabels,
    show_ticks: !!config.showTicks,
    show_tick_value: !!config.showTickValue,
    enable_colors: !!config.enableColors,
    show_label: !!config.showValue, // this is correct: showLabel is now showValue, but we're keeping show_label for backward compatibility
    min_label: config.minLabel,
    max_label: config.maxLabel,
  }
}

export const parseSliderTypeRemoteQuestionConfig = (config: RemoteSliderQuestion['config']) => ({
  sliderType: config.slider_type || SliderTypeOptions.Horizontal, // for question without this property on BE it has to be horizontal
  minValue: parseFloat(config.min_value),
  maxValue: parseFloat(config.max_value),
  increment: parseFloat(config.increment),
  showValue: config.show_label !== false, // for question without this property on BE it has to be true
  showLabels: config.show_labels !== false,
  showTicks: !!config.show_ticks, // for question without this property on BE it has to be false
  showTickValue: !!config.show_tick_value,
  enableColors: !!config.enable_colors,
  minLabel: config.min_label,
  maxLabel: config.max_label,
})
