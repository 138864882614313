import { TemplateApiPaths, createFromTemplates, insertTemplates } from '../forms'

interface InsertRecruitmentBlockTemplatesOptions {
  recruitmentId: string
  subsectionId: string
  templateIds: string[]
}

const eproBlockTemplateMapping = {
  recruitmentId: 'recruitment_uuid',
  subsectionId: 'subsection_id',
  templateIds: 'template_ids',
}

export const insertRecruitmentBlockTemplates = insertTemplates<InsertRecruitmentBlockTemplatesOptions>(
  TemplateApiPaths.RecruitmentBlocks,
  eproBlockTemplateMapping,
)

interface InsertRecruitmentSubsectionTemplatesOptions {
  studyId: string
  recruitmentId: string
  sectionId: string
  templateIds: string[]
}

const eproSubsectionTemplateMapping = {
  recruitmentId: 'recruitment_uuid',
  sectionId: 'section_id',
  templateIds: 'template_ids',
}

export const insertRecruitmentSubsectionTemplates = insertTemplates<InsertRecruitmentSubsectionTemplatesOptions>(
  TemplateApiPaths.RecruitmentSubsections,
  eproSubsectionTemplateMapping,
)

interface InsertRecruitmentSectionTemplatesOptions {
  recruitmentId: string
  templateIds: string[]
}

const eproSectionTemplateMapping = {
  recruitmentId: 'recruitment_uuid',
  templateIds: 'template_ids',
}

export const insertRecruitmentSectionTemplates = insertTemplates<InsertRecruitmentSectionTemplatesOptions>(
  TemplateApiPaths.RecruitmentSections,
  eproSectionTemplateMapping,
)

interface InsertRecruitmentFullTemplatesOptions {
  recruitmentId: string
  templateId: string
}

const eproFullTemplateMapping = {
  recruitmentId: 'recruitment_uuid',
  templateId: 'template_id',
}

export const insertRecruitmentFullTemplates = insertTemplates<InsertRecruitmentFullTemplatesOptions>(
  TemplateApiPaths.RecruitmentFull,
  eproFullTemplateMapping,
)

interface CreateRecruitmentFullTemplatesOptions {
  recruitmentId: string
  templateId?: string
}

const createRecruitmentFullFromTemplateMapping = {
  recruitmentId: 'recruitment_uuid',
  templateId: 'template_id',
}

export const createRecruitmentFromFullTemplates = createFromTemplates<CreateRecruitmentFullTemplatesOptions>(
  TemplateApiPaths.RecruitmentFull,
  createRecruitmentFullFromTemplateMapping,
)

interface CreateRecruitmentTemplatesOptions {
  studyId: string
  recruitmentId: string
  templateIds?: string[]
}

const createRecruitmentFromTemplateMapping = {
  recruitmentId: 'recruitment_uuid',
  templateIds: 'template_ids',
}

export const createRecruitmentFromSectionTemplates = createFromTemplates<CreateRecruitmentTemplatesOptions>(
  TemplateApiPaths.RecruitmentSections,
  createRecruitmentFromTemplateMapping,
)

export const createRecruitmentFromSubsectionTemplates = createFromTemplates<CreateRecruitmentTemplatesOptions>(
  TemplateApiPaths.RecruitmentSubsections,
  createRecruitmentFromTemplateMapping,
)
