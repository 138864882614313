/* eslint-disable camelcase */
import { createErrorsHandlers, prepareSorter } from '../../utils'
import { Feature } from '../Feature'
import { BackendError } from '../RequestError'
import { SorterOrder } from '../SorterOrder'
import { DataMapping, fetchApi, mapData } from '../fetchApi'
import { RemoteFile, parseRemoteFile } from '../file'

export enum TemplateApiPaths {
  Full = 'templates/full',
  Sections = 'templates/sections',
  Subsections = 'templates/subsections',
  Blocks = 'templates/blocks',
  EproFull = 'templates/full/epro',
  RecruitmentFull = 'templates/full/recruitment',
  ProjectFull = 'templates/full/project',
  EcrfFull = 'templates/full/ecrf',
  EconsentFull = 'templates/full/econsent',
  EproSections = 'templates/sections/epro',
  RecruitmentSections = 'templates/sections/recruitment',
  ProjectSections = 'templates/sections/project',
  EcrfSections = 'templates/sections/ecrf',
  EconsentSections = 'templates/sections/econsent',
  EproSubsections = 'templates/subsections/epro',
  RecruitmentSubsections = 'templates/subsections/recruitment',
  ProjectSubsections = 'templates/subsections/project',
  EcrfSubsections = 'templates/subsections/ecrf',
  EconsentSubsections = 'templates/subsections/econsent',
  EproBlocks = 'templates/blocks/epro',
  RecruitmentBlocks = 'templates/blocks/recruitment',
  ProjectBlocks = 'templates/blocks/project',
  EcrfBlocks = 'templates/blocks/ecrf',
  EconsentBlocks = 'templates/blocks/econsent',
}

export type BaseSaveTemplateOptions = {
  title: string
  description: string
  tags: string[]
  recruitmentId?: string
  projectId?: string
}

const baseTemplateMapping = {
  title: 'title',
  description: 'description',
  tags: 'tags',
  recruitmentId: 'recruitment_uuid',
  projectId: 'project_uuid',
}

export interface SaveTemplateResponseHandlers {
  onSuccess?: () => void
  onTitleDuplicate?: () => void
  onInvalidSectionId?: () => void
  onNoStructureError?: () => void
  onRequestError?: (code: number) => void
}

const saveTemplate =
  <T>(path: string, dataMapping: DataMapping<T>) =>
  (data: T & { moduleType: Feature; studyId: string }, responseHandlers?: SaveTemplateResponseHandlers) => {
    const query = {
      ...mapData(dataMapping, data),
      module_type: data.moduleType,
    }
    const { req, cancel } = fetchApi.post(path, query, { studyId: data.studyId })

    req.then(({ error, status }) => {
      if (error) {
        createErrorsHandlers<SaveTemplateResponseHandlers>(
          {
            [BackendError.TEMPLATE_TITLE_ALREADY_EXISTS]: 'onTitleDuplicate',
            [BackendError.ECRF_NOT_EXISTS]: 'onInvalidSectionId',
            [BackendError.ECRF_SECTION_NOT_EXISTS]: 'onNoStructureError',
            [BackendError.ECONSENT_NOT_EXISTS]: 'onNoStructureError',
            [BackendError.EPRO_NOT_EXISTS]: 'onNoStructureError',
          },
          error,
          responseHandlers,
          status,
        )
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess()
      }
    })

    return cancel
  }

export interface SaveFullTemplateOptions extends BaseSaveTemplateOptions {
  studyId: string
}

const saveFullTemplateMapping = baseTemplateMapping

export interface SaveFullEproTemplateOptions extends BaseSaveTemplateOptions {
  studyId: string
  eproId: string
}

const saveFullEproTemplateMapping = {
  ...baseTemplateMapping,
  eproId: 'epro_id',
}

export interface SaveFullEconsentTemplateOptions extends BaseSaveTemplateOptions {
  studyId: string
  formId: string
}

const saveFullEconsentTemplateMapping = {
  ...baseTemplateMapping,
  formId: 'econsent_id',
}

export interface SaveSectionTemplateOptions extends BaseSaveTemplateOptions {
  sectionId: string
}

const saveSectionTemplateMapping = {
  ...baseTemplateMapping,
  sectionId: 'section_id',
}

export interface SaveSubsectionTemplateOptions extends BaseSaveTemplateOptions {
  subsectionId: string
}

const saveSubsectionTemplateMapping = {
  ...baseTemplateMapping,
  subsectionId: 'subsection_id',
}

export interface SaveBlockTemplateOptions extends BaseSaveTemplateOptions {
  blockId: string
}

const saveBlockTemplateMapping = {
  ...baseTemplateMapping,
  blockId: 'block_id',
}

export const saveFullTemplate = saveTemplate<SaveFullTemplateOptions>(TemplateApiPaths.Full, saveFullTemplateMapping)

export const saveFullEproTemplate = saveTemplate<SaveFullEproTemplateOptions>(
  TemplateApiPaths.Full,
  saveFullEproTemplateMapping,
)

export const saveFullEconsentTemplate = saveTemplate<SaveFullEconsentTemplateOptions>(
  TemplateApiPaths.Full,
  saveFullEconsentTemplateMapping,
)

export const saveSectionTemplate = saveTemplate<SaveSectionTemplateOptions>(
  TemplateApiPaths.Sections,
  saveSectionTemplateMapping,
)

export const saveSubsectionTemplate = saveTemplate<SaveSubsectionTemplateOptions>(
  TemplateApiPaths.Subsections,
  saveSubsectionTemplateMapping,
)

export const saveBlockTemplate = saveTemplate<SaveBlockTemplateOptions>(
  TemplateApiPaths.Blocks,
  saveBlockTemplateMapping,
)

export interface CreateFromTemplatesResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const createFromTemplates =
  <T>(path: string, dataMapping: DataMapping<T>) =>
  (data: T & { studyId: string }, responseHandlers?: CreateFromTemplatesResponseHandlers) => {
    const { req, cancel } = fetchApi.post(path, mapData(dataMapping, data), { studyId: data.studyId })

    req.then(({ error, status }) => {
      if (error) {
        createErrorsHandlers<CreateFromTemplatesResponseHandlers>({}, error, responseHandlers, status)
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess()
      }
    })

    return cancel
  }

interface RemoteTemplate {
  id: number
  title: string
  description: string
  owner_name: string
  creation_date: string
  type?: TemplateType
  tags: string[]
}

export enum TemplateType {
  Full = 'FULL',
  Section = 'SECTION',
  Subsection = 'SUBSECTION',
  Block = 'BLOCK',
}

export interface Template {
  id: string
  title: string
  description: string
  ownerName: string
  createdDate: Date
  type: TemplateType
  tags: string[]
}

export interface TemplateSorter {
  field: keyof Template
  order: SorterOrder
}

export interface FetchTemplatesOptions {
  studyId?: string
  target: Feature
  templateType?: TemplateType
  options?: {
    limit?: number
    offset?: number
    sorter?: TemplateSorter
    search?: string
    tags?: string[]
    isInRepeatedMeasure?: boolean
  }
}
interface FetchTemplatesResponse {
  results: RemoteTemplate[]
  count?: number
}

const sorterFields = {
  id: ['id'],
  ownerName: ['owner_name'],
  createdDate: ['creation_date'],
}

const createRemoteTemplateParser: (type?: TemplateType) => (t: RemoteTemplate) => Template = type => template => {
  return {
    type: type ?? template.type,
    id: template.id && String(template.id),
    title: template.title,
    description: template.description,
    ownerName: template.owner_name,
    createdDate: new Date(template.creation_date),
    tags: template.tags,
  }
}

export interface FetchTemplatesResponseHandlers {
  onSuccess?: (templates: Template[], allTemplatesCount?: number) => void
  onRequestError?: (code: number) => void
}

interface FetchTemplatesResponse {
  results: RemoteTemplate[]
}

interface FetchTemplates {
  (
    templatesOptions: FetchTemplatesOptions,
    responseHandlers: FetchTemplatesResponseHandlers,
    templateType?: TemplateType,
  ): void
}

export const fetchTemplates: FetchTemplates = (templatesOptions, responseHandlers) => {
  return fetchTemplatesRequest(templatesOptions, responseHandlers)
}

export const fetchFullTemplates: FetchTemplates = (templatesOptions, responseHandlers) => {
  return fetchTemplatesRequest(templatesOptions, responseHandlers, TemplateType.Full)
}

export const fetchSectionTemplates: FetchTemplates = (templatesOptions, responseHandlers) => {
  return fetchTemplatesRequest(templatesOptions, responseHandlers, TemplateType.Section)
}

export const fetchSubsectionTemplates: FetchTemplates = (templatesOptions, responseHandlers) => {
  return fetchTemplatesRequest(templatesOptions, responseHandlers, TemplateType.Subsection)
}

export const fetchBlockTemplates: FetchTemplates = (templatesOptions, responseHandlers) => {
  return fetchTemplatesRequest(templatesOptions, responseHandlers, TemplateType.Block)
}

const prepareApiPath = (templateType: TemplateType) => {
  switch (templateType) {
    case TemplateType.Full:
      return TemplateApiPaths.Full
    case TemplateType.Section:
      return TemplateApiPaths.Sections
    case TemplateType.Subsection:
      return TemplateApiPaths.Subsections
    case TemplateType.Block:
      return TemplateApiPaths.Blocks
    default:
      return 'templates'
  }
}

const fetchTemplatesRequest: FetchTemplates = ({ studyId, target, options }, responseHandlers, templateType) => {
  const path = prepareApiPath(templateType)
  const sorter = prepareSorter<typeof sorterFields, TemplateSorter>(sorterFields, options.sorter, 'id')
  const query = {
    module_type: target,
    search: options.search,
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    tags: options.tags,
    no_repeated_measures: options.isInRepeatedMeasure,
  }
  const { req, cancel } = fetchApi.get<FetchTemplatesResponse>(path, query, { studyId })

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<FetchTemplatesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.results.map(createRemoteTemplateParser(templateType)), body.count)
    }
  })

  return cancel
}

interface DeleteTemplateOptions {
  templateId: string
}

interface DeleteTemplateResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code?: number) => void
}

export const deleteTemplate = (options: DeleteTemplateOptions, responseHandlers: DeleteTemplateResponseHandlers) => {
  const { req, cancel } = fetchApi.delete(`templates/${options.templateId}`)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteTemplateResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export interface InsertBlockTemplatesOptions {
  subsectionId: string
  repeatedMeasureId?: string
  templateIds: string[]
}

export interface InsertTemplatesResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onSectionsLimitReached?: () => void
  onSubsectionsLimitReached?: () => void
  onRepeatedMeasuresLimitReached?: () => void
  onQuestionsLimitReached?: () => void
  onStaticContentLimitReached?: () => void
  onButtonsLimitReached?: () => void
  onError?: () => void
}

export const insertTemplates =
  <T>(path: string, dataMapping: DataMapping<T>) =>
  (data: T & { studyId: string }, responseHandlers?: InsertTemplatesResponseHandlers) => {
    const { req, cancel } = fetchApi.patch(path, mapData(dataMapping, data), { studyId: data.studyId })
    req.then(({ error, status }) => {
      if (error) {
        createErrorsHandlers<InsertTemplatesResponseHandlers>(
          {
            [BackendError.ECRF_SECTION_LIMIT_REACHED]: 'onSectionsLimitReached',
            [BackendError.ECRF_SUBSECTION_LIMIT_REACHED]: 'onSubsectionsLimitReached',
            [BackendError.ECRF_REPEATED_MEASURE_LIMIT_REACHED]: 'onRepeatedMeasuresLimitReached',
            [BackendError.ECRF_QUESTION_LIMIT_REACHED]: 'onQuestionsLimitReached',
            [BackendError.ECRF_STATIC_CONTENT_LIMIT_REACHED]: 'onStaticContentLimitReached',
            [BackendError.ECRF_BUTTON_CONTENT_LIMIT_REACHED]: 'onButtonsLimitReached',
          },
          error,
          responseHandlers,
          status,
        )
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess()
      }
    })

    return cancel
  }

type FetchTagsResponse = string[]

interface FetchTagsResponseHandlers {
  onSuccess?: (tags: string[]) => void
  onRequestError?: (code: number) => void
}

export const fetchTags = ({ studyId }: { studyId?: string }, responseHandlers?: FetchTagsResponseHandlers) => {
  const { req, cancel } = fetchApi.get<FetchTagsResponse>('templates/tags', {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchTagsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}

interface ImportTemplateOptions {
  file: File
}

interface ImportTemplateResponseHandlers {
  onSuccess?: (savedFile: ReturnType<typeof parseRemoteFile>) => void
  onRequestError?: (code?: number) => void
}

export const importTemplate = ({ file }: ImportTemplateOptions, responseHandlers: ImportTemplateResponseHandlers) => {
  const { req, cancel } = fetchApi.postFile<RemoteFile>('templates/import', { file })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<ImportTemplateResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteFile(body))
    }
  })

  return cancel
}
