import { changeLocale, createErrorsHandlers, getLanguage, setLanguage } from '../../utils'
import { fetchApi } from '../fetchApi'
import { defaultMaxFileBytes } from '../file'

export const defaultStudyColor = '#3a63f3'

export enum DateFormat {
  AU = 'd/MM/yyyy',
  CA = 'yyyy-MM-dd',
  DE = 'dd.MM.yyyy',
  PH = 'M/d/yyyy',
  UK = 'dd/MM/yyyy',
  US = 'MM/dd/yyyy',
}

interface RemoteUiSettings {
  study_uuid: string
  logo_url: string
  main_colour: string
  button_colour: string
  language: string
  file_size_limit: number
  date_format: DateFormat
  esignature_enabled: boolean
}

export interface UiSettings {
  studyId: string
  logoUrl: string
  mainColor: string
  buttonColor: string
  languages?: string[]
  maxFileBytes: number
  dateFormat: DateFormat
  esignatureEnabled?: boolean
}

const parseFetchUiSettingsResponse = (response: RemoteUiSettings) => ({
  studyId: response.study_uuid,
  logoUrl: response.logo_url,
  mainColor: response.main_colour || defaultStudyColor,
  buttonColor: response.button_colour || defaultStudyColor,
  maxFileBytes: response.file_size_limit || defaultMaxFileBytes,
  dateFormat: response.date_format || DateFormat.CA,
  esignatureEnabled: !!response.esignature_enabled,
})

interface FetchUiSettingsResponseHandlers {
  onSuccess?: (uiSettings: UiSettings) => void
  onNotFound?: () => void
  onRequestError?: (code: number) => void
}

export const fetchUiSettings = ({ token }: { token: string }, responseHandlers?: FetchUiSettingsResponseHandlers) => {
  const { req, cancel } = fetchApi.get<RemoteUiSettings>(`subject_accounts/ui_settings/${token}`, {})

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<FetchUiSettingsResponseHandlers>(
        {
          404: 'onNotFound',
        },
        error,
        responseHandlers,
        status,
      )
    } else if (responseHandlers?.onSuccess) {
      const language = getLanguage() || body.language
      setLanguage(language)

      changeLocale(language)
      responseHandlers.onSuccess(parseFetchUiSettingsResponse(body))
    }
  })

  return cancel
}
