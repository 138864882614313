/* eslint-disable camelcase */
import { QuestionBase, QuestionType, RemoteQuestionBase } from './base'

export interface RadioValue {
  selected: string
  other: string
}

export enum RadioValidationRuleOperator {
  Equal = 'EQUAL',
  NotEqual = 'NOT_EQUAL',
}

export interface RemoteSelectQuestion extends RemoteQuestionBase {
  type: QuestionType.Radio | QuestionType.Checkbox | QuestionType.Dropdown
  config: {
    options: { label: string; value: string; number_label: string }[]
    other: boolean
    other_label?: string
    arrangement?: string
  }
}

export enum Arrangement {
  Vertical = 'VERTICAL',
  Horizontal = 'HORIZONTAL',
}
export interface SelectQuestion extends QuestionBase {
  config: {
    options: {
      label: string
      value: string
      numberLabel: number
    }[]
    other: boolean
    otherLabel?: string
    arrangement: Arrangement
  }
}

export interface CheckboxQuestion extends SelectQuestion {
  type: QuestionType.Checkbox
}

export interface RadioQuestion extends SelectQuestion {
  type: QuestionType.Radio
}

export interface DropdownQuestion extends SelectQuestion {
  type: QuestionType.Dropdown
}

export const parseSelectTypeRemoteQuestionConfig = (config: RemoteSelectQuestion['config']) => ({
  options: config.options.map(option => ({
    label: option.label,
    value: option.value,
    numberLabel: option.number_label,
  })),
  other: config.other,
  otherLabel: config.other_label,
  arrangement: config.arrangement ? config.arrangement : Arrangement.Vertical,
})

export const prepareSelectQuestionConfigForSave = (config: SelectQuestion['config'], _questionType: QuestionType) => {
  let smallestNumberLabel = 1
  const addUniqueNumberLabel = (currentNumber: number): number => {
    if (config.options.find(option => option.numberLabel === currentNumber)) {
      return addUniqueNumberLabel(currentNumber + 1)
    }
    smallestNumberLabel = currentNumber + 1
    return currentNumber
  }

  return {
    options: config.options.map(option => ({
      label: option.label,
      value: option.value,
      number_label: option.numberLabel ?? addUniqueNumberLabel(smallestNumberLabel),
    })),
    other: config.other,
    other_label: config.otherLabel,
    arrangement: config.arrangement,
  }
}
