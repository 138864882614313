/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../../utils'
import { Feature } from '../../Feature'
import { BackendError } from '../../RequestError'
import { fetchApi } from '../../fetchApi'
import {
  ButtonContent,
  RemoteButtonContent,
  parseRemoteButtonContent,
  prepareButtonContentForSave,
} from './buttonContent'
import { Question, RemoteQuestion, parseRemoteQuestion, prepareQuestionForSave } from './questions'
import {
  RemoteRepeatedMeasures,
  RepeatedMeasures,
  parseRemoteRepeatedMeasures,
  prepareRepeatedMeasuresForSave,
} from './repeatedMeasures'
import {
  RemoteStaticContent,
  StaticContent,
  parseRemoteStaticContent,
  prepareStaticContentForSave,
} from './staticContent'
import { TableMatrix, parseRemoteTableMatrix, prepareTableMatrixForSave } from './tableMatrix'

export enum BlockType {
  Question = 'Question',
  StaticContent = 'StaticContent',
  RepeatedMeasures = 'RepeatedMeasure',
  TableMatrix = 'TableMatrix',
  ButtonContent = 'ButtonContent',
}

export enum RemoteBlockType {
  Question = 'QUESTION',
  StaticContent = 'STATIC_CONTENT',
  RepeatedMeasured = 'REPEATED_MEASURE',
  ButtonContent = 'BUTTON_CONTENT',
}

export enum RepeatedMeasuresType {
  List = 'LIST',
  Table = 'TABLE',
}

export type RemoteQuestionBlock<T = RemoteQuestion> = T & { block_type: RemoteBlockType.Question }
export type RemoteStaticContentBlock = RemoteStaticContent & { block_type: RemoteBlockType.StaticContent }
export type RemoteButtonContentBlock = RemoteButtonContent & { block_type: RemoteBlockType.ButtonContent }
export type RemoteRepeatedMeasuresBlock = RemoteRepeatedMeasures & {
  block_type: RemoteBlockType.RepeatedMeasured
}

export type RemoteBlock =
  | RemoteQuestionBlock
  | RemoteStaticContentBlock
  | RemoteRepeatedMeasuresBlock
  | RemoteButtonContentBlock

export type QuestionBlock<T = Question> = T & { blockType: BlockType.Question }
export type StaticContentBlock = StaticContent & { blockType: BlockType.StaticContent }
export type ButtonContentBlock = ButtonContent & { blockType: BlockType.ButtonContent }
export type RepeatedMeasuresBlock = RepeatedMeasures & {
  blockType: BlockType.RepeatedMeasures
}
export type TableMatrixBlock = TableMatrix & {
  blockType: BlockType.TableMatrix
}

export type Block = QuestionBlock | StaticContentBlock | RepeatedMeasuresBlock | TableMatrixBlock | ButtonContentBlock

type FeatureBlockOptions =
  | { target: Feature.Epro; eproId: string }
  | { target: Feature.Ecrf }
  | { target: Feature.Econsent }
  | { target: Feature.SubjectRepository }
  | { target: Feature.Recruitment; recruitmentId: string }
  | { target: Feature.SideBySide; projectId: string }

type BlockUrlOptions = FeatureBlockOptions & {
  blockId?: string
  sectionId: string
  subsectionId: string
  blockType?: BlockType
}

const prepareBlockUrl = (options: BlockUrlOptions) => {
  const blockPart = options.blockId ? `/${options.blockId}` : ''
  const repeatedMeasuresPart = [BlockType.RepeatedMeasures, BlockType.TableMatrix].includes(options.blockType)
    ? '/repeated_measure'
    : ''
  const commonPart = `${options.sectionId}/${options.subsectionId}/block${repeatedMeasuresPart}${blockPart}`

  switch (options.target) {
    case Feature.Ecrf:
      return `ecrf/${commonPart}`
    case Feature.Epro:
      return `epro/${options.eproId}/${commonPart}`
    case Feature.Econsent:
      return `econsent/${commonPart}`
    case Feature.SubjectRepository:
      return `subject_repository/${commonPart}`
    case Feature.Recruitment:
      return `recruitment/${options.recruitmentId}/${commonPart}`
    case Feature.SideBySide:
      return `side_by_side/${options.projectId}/${commonPart}`
    default:
      return ''
  }
}

export const parseRemoteBlock: (remoteBlock: RemoteBlock) => Block = (remoteBlock: RemoteBlock) => {
  if (remoteBlock.block_type === RemoteBlockType.Question) {
    return {
      blockType: BlockType.Question,
      ...parseRemoteQuestion(remoteBlock),
    } as QuestionBlock
  }

  if (remoteBlock.block_type === RemoteBlockType.StaticContent) {
    return {
      blockType: BlockType.StaticContent,
      ...parseRemoteStaticContent(remoteBlock),
    } as StaticContentBlock
  }

  if (remoteBlock.block_type === RemoteBlockType.RepeatedMeasured) {
    if (remoteBlock.type === RepeatedMeasuresType.Table) {
      return {
        blockType: BlockType.TableMatrix,
        ...parseRemoteTableMatrix(remoteBlock),
      } as TableMatrixBlock
    }
    return {
      blockType: BlockType.RepeatedMeasures,
      ...parseRemoteRepeatedMeasures(remoteBlock),
    } as RepeatedMeasuresBlock
  }

  if (remoteBlock.block_type === RemoteBlockType.ButtonContent) {
    return {
      blockType: BlockType.ButtonContent,
      ...parseRemoteButtonContent(remoteBlock),
    } as ButtonContentBlock
  }

  return null
}

export const prepareQuestionBlockForSave = (
  block: QuestionBlock,
  order: number,
  subsectionId: string,
  isRequiredNotApplicable?: boolean,
) => ({
  block_type: RemoteBlockType.Question,
  ...prepareQuestionForSave(block, order, subsectionId, isRequiredNotApplicable),
})

export const prepareStaticContentBlockForSave = (block: StaticContentBlock, order: number, subsectionId: string) => ({
  block_type: RemoteBlockType.StaticContent,
  ...prepareStaticContentForSave(block, order, subsectionId),
})

export const prepareRepeatedMeasuresBlockForSave = (
  block: RepeatedMeasuresBlock,
  order: number,
  subsectionId: string,
) => ({
  block_type: RemoteBlockType.RepeatedMeasured,
  type: RepeatedMeasuresType.List,
  ...prepareRepeatedMeasuresForSave(block, order, subsectionId),
})

export const prepareTableMatrixBlockForSave = (block: TableMatrixBlock, order: number, subsectionId: string) => ({
  block_type: RemoteBlockType.RepeatedMeasured,
  type: RepeatedMeasuresType.Table,
  ...prepareTableMatrixForSave(block, order, subsectionId),
})

export const prepareButtonContentBlockForSave = (block: ButtonContentBlock, order: number, subsectionId: string) => ({
  block_type: RemoteBlockType.ButtonContent,
  ...prepareButtonContentForSave(block, order, subsectionId),
})

export const prepareBlockForSave = (block: Block, order: number, subsectionId: string) => {
  if (block.blockType === BlockType.Question) {
    return prepareQuestionBlockForSave(block, order, subsectionId)
  }

  if (block.blockType === BlockType.StaticContent) {
    return prepareStaticContentBlockForSave(block, order, subsectionId)
  }

  if (block.blockType === BlockType.TableMatrix) {
    return prepareTableMatrixBlockForSave(block, order, subsectionId)
  }

  if (block.blockType === BlockType.RepeatedMeasures) {
    return prepareRepeatedMeasuresBlockForSave(block, order, subsectionId)
  }

  if (block.blockType === BlockType.ButtonContent) {
    return prepareButtonContentBlockForSave(block, order, subsectionId)
  }

  return null
}

type SaveBlockOptions = FeatureBlockOptions & {
  sectionId: string
  subsectionId: string
  studyId: string
  order: number
  block: Block
}

export interface SaveBlockResponseHandlers {
  onSuccess?: (block: Block) => void
  onRequestError?: (code: number) => void
  onDuplicateVariable?: () => void
  onCalculIncorrectFormula?: () => void
  onCalculVariableMismatch?: () => void
  onMaxQuestionsCountReached?: () => void
  onMaxStaticContentCountReached?: () => void
  onMaxRepeatedMeasuresCountReached?: () => void
  onMaxTableMatrixCountReached?: () => void
  onQuestionUsedInStratification?: () => void
  onQuestionIsReference?: () => void
  onProtectedVariable?: () => void
  onInvalidBlockError?: () => void
}

const updateBlock = (options: SaveBlockOptions) => {
  const { subsectionId, studyId, order, block } = options

  const query = prepareBlockForSave(block, order, subsectionId)
  const path = prepareBlockUrl({ ...options, blockId: block.id, blockType: block.blockType })
  const { req, cancel } = fetchApi.patch<RemoteBlock>(path, query, { studyId })

  return { req, cancel }
}

const createBlock = (options: SaveBlockOptions) => {
  const { subsectionId, studyId, order, block } = options
  const path = prepareBlockUrl({ ...options, blockId: block.id })
  const { req, cancel } = fetchApi.post<RemoteBlock>(path, prepareBlockForSave(block, order, subsectionId), { studyId })

  return { req, cancel }
}

export const saveBlock = (options: SaveBlockOptions, responseHandlers?: SaveBlockResponseHandlers) => {
  let parsedOptions = options

  // Clean config from empty values (backend expects no key for empty config)
  if (options.block.blockType === BlockType.Question && options.block.config) {
    const config = Object.entries(options.block.config).reduce((acc, [key, value]) => {
      if (value !== '' && value !== undefined) {
        acc[key] = value
      }

      return acc
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {} as any)

    parsedOptions = { ...options, block: { ...options.block, config } }
  }

  const { req, cancel } = options.block.id ? updateBlock(parsedOptions) : createBlock(parsedOptions)

  req.then(({ body: block, error, status }) => {
    if (error) {
      createErrorsHandlers<SaveBlockResponseHandlers>(
        {
          [BackendError.ECRF_QUESTION_LIMIT_REACHED]: 'onMaxQuestionsCountReached',
          [BackendError.ECRF_STATIC_CONTENT_LIMIT_REACHED]: 'onMaxStaticContentCountReached',
          [BackendError.ECRF_REPEATED_MEASURE_LIMIT_REACHED]: 'onMaxRepeatedMeasuresCountReached',
          [BackendError.ECRF_QUESTION_VARIABLE_NOT_UNIQUE]: 'onDuplicateVariable',
          [BackendError.CALCUL_INCORRECT_FORMULA_FORMAT]: 'onCalculIncorrectFormula',
          [BackendError.CALCUL_VARIABLE_MISMATCH]: 'onCalculVariableMismatch',
          [BackendError.ECRF_QUESTION_USED_IN_STRATIFICATION]: 'onQuestionUsedInStratification',
          [BackendError.CONDITIONAL_LOGIC_MISSING_REFERENCE]: 'onInvalidBlockError',
          [BackendError.CONDITIONAL_LOGIC_TYPE_CHANGE_NOT_ALLOWED]: 'onQuestionIsReference',
          [BackendError.SUBJECT_REPOSITORY_VARIABLE_FORBIDDEN]: 'onProtectedVariable',
        },
        error,
        responseHandlers,
        status,
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteBlock(block))
    }
  })

  return cancel
}

type DeleteBlockOptions = FeatureBlockOptions & {
  studyId: string
  sectionId: string
  subsectionId: string
  blockId: string
}

export interface DeleteBlockResponseHandlers {
  onSuccess?: () => void
  onQuestionUsedInStratification?: () => void
  onRequestError?: (code: number) => void
}

export const deleteBlock = (options: DeleteBlockOptions, responseHandlers?: DeleteBlockResponseHandlers) => {
  const { req, cancel } = fetchApi.delete(prepareBlockUrl(options), {}, { studyId: options.studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteBlockResponseHandlers>(
        {
          [BackendError.ECRF_QUESTION_USED_IN_STRATIFICATION]: 'onQuestionUsedInStratification',
        },
        error,
        responseHandlers,
        status,
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
