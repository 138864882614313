import './DatacStudyStatusTag.less'

import React from 'react'

import { DatacStatusTag } from '..'
import { ProjectStatus, RecruitmentStudyStatus, StudyStatus } from '../../requests'

type Status = StudyStatus | RecruitmentStudyStatus | ProjectStatus

const config = {
  [StudyStatus.Draft]: {
    className: 'study-status-tag--draft',
    label: 'studies.status.draft',
  },
  [StudyStatus.Live]: {
    className: 'study-status-tag--live',
    label: 'studies.status.live',
  },
  [StudyStatus.Archived]: {
    className: 'study-status-tag--archived',
    label: 'studies.status.archived',
  },
  [StudyStatus.Ended]: {
    className: 'study-status-tag--ended',
    label: 'studies.status.ended',
  },
  [StudyStatus.Suspended]: {
    className: 'study-status-tag--suspended',
    label: 'studies.status.suspended',
  },
  [RecruitmentStudyStatus.Recruiting]: {
    className: 'study-status-tag--recruiting',
    label: 'studies.status.recruiting',
  },
}

export const DatacStudyStatusLabel: React.FC<{ status: Status; className?: string }> = ({ status, className }) => (
  <DatacStatusTag.Label status={status} className={className} config={config} />
)

export const DatacStudyStatusIndicator: React.FC<{ status: Status }> = ({ status }) => (
  <DatacStatusTag.Indicator status={status} config={config} />
)

export const DatacStudyStatusTag: React.FC<{ status: Status }> = ({ status }) => (
  <DatacStatusTag status={status} config={config} className="study-status-tag" />
)
