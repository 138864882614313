import './DatacErrorPage.less'

import { Button } from 'antd'
import React, { useEffect } from 'react'
import { useRouteError } from 'react-router'

import { DatacIcon } from '..'
import ErrorImage from '../../assets/images/error.svg?react'
import { LocalStorageKey, getLocalStorageItem } from '../../utils'

const oneMinute = 1000 * 60

// after deployment of SPA without browser reload, these errors can occur when entering new page
const spaErrors = [
  'Importing a module script failed',
  'Failed to load resource: the server responded with a status of 404 (Not Found)',
  'Failed to fetch dynamically imported module',
  'The server responded with a non-JavaScript MIME type. Importing a module script failed',
  'Uncaught (in promise) Error: Cannot find module',
  "The script has an unsupported MIME type ('text/html').",
  "Uncaught (in promise) TypeError: Failed to register a ServiceWorker: The script has an unsupported MIME type ('text/html')",
  'A network error occurred while fetching the script',
  'TypeError: Failed to fetch',
]

export const DatacErrorPage: React.FC = () => {
  const error = useRouteError()

  useEffect(() => {
    const errorMessage = error instanceof Error ? error.message : ''
    const shouldAutoReload = spaErrors.some(errorText => errorMessage.includes(errorText))
    const lastAutoReloadingTime = Number(getLocalStorageItem(LocalStorageKey.AutoReloadingTime, '0'))
    const currentTime = Date.now()

    if (!shouldAutoReload || isNaN(lastAutoReloadingTime) || currentTime - lastAutoReloadingTime < oneMinute) return

    localStorage.setItem(LocalStorageKey.AutoReloadingTime, currentTime.toString())
    window.location.reload()
  }, [error])

  return (
    <div className="datac-error-page">
      <DatacIcon raw name="datac" className="datac-error-page__logo" />
      <div className="datac-error-page__container">
        <ErrorImage className="datac-error-page__error-icon" />
        <div className="datac-error-page__content">
          <h1 className="datac-error-page__title">
            {/* reactIntl doesn't work here */}
            {error instanceof Error ? error.message : "You've encountered an error"}
          </h1>
          <div className="datac-error-page__message-container">
            <p className="datac-error-page__subtitle">
              Sorry. This page is not working properly. Here's what you can do
            </p>
            <ul className="datac-error-page__list">
              <li className="datac-error-page__list-item">
                <span className="datac-error-page__list-item__emphasis">Email us </span>
                <span className="datac-error-page__list-item__regular">at </span>
                <a href="mailto:support@datacapt.com" className="datac-error-page__list-item__link">
                  support@datacapt.com
                </a>
                <span className="datac-error-page__list-item__regular"> to let us know!</span>
              </li>
              <li className="datac-error-page__list-item">
                <span className="datac-error-page__list-item-emphasis">Refresh</span>
                <span className="datac-error-page__list-item__regular"> the page</span>
              </li>
            </ul>
          </div>
        </div>
        <Button type="default" onClick={() => window.location.reload()} aria-label="Refresh page">
          Refresh page
        </Button>
      </div>
    </div>
  )
}
