import { TemplateApiPaths, createFromTemplates, insertTemplates } from '../forms'

interface InsertProjectBlockTemplatesOptions {
  projectId: string
  subsectionId: string
  templateIds: string[]
}

const eproBlockTemplateMapping = {
  projectId: 'project_uuid',
  subsectionId: 'subsection_id',
  templateIds: 'template_ids',
}

export const insertProjectBlockTemplates = insertTemplates<InsertProjectBlockTemplatesOptions>(
  TemplateApiPaths.ProjectBlocks,
  eproBlockTemplateMapping,
)

interface InsertProjectSubsectionTemplatesOptions {
  studyId: string
  projectId: string
  sectionId: string
  templateIds: string[]
}

const projectSubsectionTemplateMapping = {
  projectId: 'project_uuid',
  sectionId: 'section_id',
  templateIds: 'template_ids',
}

export const insertProjectSubsectionTemplates = insertTemplates<InsertProjectSubsectionTemplatesOptions>(
  TemplateApiPaths.ProjectSubsections,
  projectSubsectionTemplateMapping,
)

interface InsertProjectSectionTemplatesOptions {
  projectId: string
  templateIds: string[]
}

const eproSectionTemplateMapping = {
  projectId: 'project_uuid',
  templateIds: 'template_ids',
}

export const insertProjectSectionTemplates = insertTemplates<InsertProjectSectionTemplatesOptions>(
  TemplateApiPaths.ProjectSections,
  eproSectionTemplateMapping,
)

interface InsertProjectFullTemplatesOptions {
  projectId: string
  templateId: string
}

const eproFullTemplateMapping = {
  projectId: 'project_uuid',
  templateId: 'template_id',
}

export const insertProjectFullTemplates = insertTemplates<InsertProjectFullTemplatesOptions>(
  TemplateApiPaths.ProjectFull,
  eproFullTemplateMapping,
)

interface CreateProjectFullTemplatesOptions {
  projectId: string
  templateId?: string
}

const createProjectFullFromTemplateMapping = {
  projectId: 'project_uuid',
  templateId: 'template_id',
}

export const createProjectFromFullTemplates = createFromTemplates<CreateProjectFullTemplatesOptions>(
  TemplateApiPaths.ProjectFull,
  createProjectFullFromTemplateMapping,
)

interface CreateProjectTemplatesOptions {
  studyId: string
  projectId: string
  templateIds?: string[]
}

const createProjectFromTemplateMapping = {
  projectId: 'project_uuid',
  templateIds: 'template_ids',
}

export const createProjectFromSectionTemplates = createFromTemplates<CreateProjectTemplatesOptions>(
  TemplateApiPaths.ProjectSections,
  createProjectFromTemplateMapping,
)

export const createProjectFromSubsectionTemplates = createFromTemplates<CreateProjectTemplatesOptions>(
  TemplateApiPaths.ProjectSubsections,
  createProjectFromTemplateMapping,
)
